import { useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components/macro';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useTranslation } from 'react-i18next';
import {
  AirlineTicketClass,
  AroundCityTransport,
  CalculatorFormValues,
  FromAirportTransport,
  MainTransport,
  StayingPlace,
} from 'common/types';
import * as utils from 'utils';
import { MONACO_MAP_POINT } from 'common/constants/map-point';
import { GoodIcon, RightArrowIcon } from '@net0-calc/common/assets/svg';
import { DEVICE, TYPOGRAPHY } from '@net0-calc/common/assets/styles';

gsap.registerPlugin(ScrollTrigger);

const translationPrefix = 'emiratesPersonal.pages.result.reductionOpportunities';

type Props = {
  formValues: CalculatorFormValues;
};

export const ReductionOpportunities = ({ formValues }: Props) => {
  const { t, i18n } = useTranslation();
  const resolvedLanguage = i18n.resolvedLanguage || 'en';

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const blocksAnimationTimeLine = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        scrub: 1,
        start: 'top 500px',
        end: 'bottom bottom',
      },
    });
    blocksAnimationTimeLine
      .from(containerRef.current, {
        opacity: 0.2,
      })
      .to(containerRef.current, {
        opacity: 1,
        duration: 0.7,
      });
  }, []);

  /**
   * TODO: think about, should this logic be moved to the FormValuesChecker class
   * const formValuesChecker = new FormValuesChecker(formValues);
   * formValuesChecker.mainTransport.isWalk();
   * formValuesChecker.stayingPlace.isPrivateAccomodation();
   * formValuesChecker.stayingPlace.isPrivateAccomodation();
   * formValuesChecker.fromLocation.distance.more(300, 'km');
   */
  const isMainTransportAirplane = formValues.mainTransport === MainTransport.AIRPLANE;
  const isMainTransportWalk = formValues.mainTransport === MainTransport.WALK;
  const isMainTransportCycle = formValues.mainTransport === MainTransport.CYCLE;
  const isArilineTicketClassEconomy = formValues.airlineTicketClass === AirlineTicketClass.ECONOMY;
  const isFromAirportTransportTrain = formValues.fromAirportTransport === FromAirportTransport.TRAIN;
  const isStayingPlacePrivateAccomodation = formValues.stayingPlace === StayingPlace.PRIVATE_ACCOMODATION;
  const isAroundCityTransportOnlyWalkOrCycle = utils.hasOnly(formValues.aroundCityTransports || [], [
    AroundCityTransport.WALK,
    AroundCityTransport.CYCLE,
  ]);
  const distacneToMonakoKm = formValues.fromLocation
    ? utils.calculateKmDistanceBetweenMapPoints(formValues.fromLocation?.mapPoint, MONACO_MAP_POINT)
    : 0;

  const renderItem = (condition: boolean, params: { title: string; description?: string }) => {
    const { title, description } = params;
    return (
      condition && (
        <StepWrap>
          <StepTitleWrap>
            <GoodIcon stroke="var(--color12)" />
            {title}
          </StepTitleWrap>
          {description && <StepParagraph dangerouslySetInnerHTML={{ __html: description }}></StepParagraph>}
        </StepWrap>
      )
    );
  };

  return (
    <Root ref={containerRef}>
      <Title>
        <EmojiContainer>🌱</EmojiContainer>{' '}
        <span dangerouslySetInnerHTML={{ __html: t(`${translationPrefix}.title`) }}></span>
      </Title>
      {renderItem(isMainTransportAirplane && !isArilineTicketClassEconomy, {
        title: t(`${translationPrefix}.flyInEconomyClass.title`),
        description: t(`${translationPrefix}.flyInEconomyClass.description`),
      })}
      {renderItem(isMainTransportAirplane && !isFromAirportTransportTrain, {
        title: t(`${translationPrefix}.takeTrainFromAirport.title`),
        description: t(`${translationPrefix}.takeTrainFromAirport.description`),
      })}
      <StepWrap>
        <StepTitleWrap>
          <GoodIcon stroke="var(--color12)" />
          {t(`${translationPrefix}.sustainableHospitalityOptions.title`)}
        </StepTitleWrap>
        <StepParagraph>{t(`${translationPrefix}.sustainableHospitalityOptions.description`)}</StepParagraph>
      </StepWrap>
      {renderItem(!isStayingPlacePrivateAccomodation, {
        title: t(`${translationPrefix}.stayInPrivateAccomodation.title`),
        description: t(`${translationPrefix}.stayInPrivateAccomodation.description`),
      })}
      {renderItem(!isAroundCityTransportOnlyWalkOrCycle, {
        title: t(`${translationPrefix}.walkOrCycleInMonaco.title`),
        description: t(`${translationPrefix}.walkOrCycleInMonaco.description`),
      })}
      {renderItem(distacneToMonakoKm <= 300 && !isMainTransportWalk && !isMainTransportCycle, {
        title: t(`${translationPrefix}.walkOrCycleToGetIntoMonaco.title`),
        description: t(`${translationPrefix}.walkOrCycleToGetIntoMonaco.description`),
      })}
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
`;

const Title = styled.div`
  ${TYPOGRAPHY.bodyMedium20}
  color: var(--color6);
  margin-bottom: 20px;
  display: flex;
  @media ${DEVICE.laptop} {
    ${TYPOGRAPHY.titleSemibold32}
    margin-bottom: 32px;
  }
`;

const StepWrap = styled.div`
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  @media ${DEVICE.laptop} {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
`;

const Link = styled.a`
  ${TYPOGRAPHY.bodyRegular16}
  cursor: pointer;
`;

const StepTitleWrap = styled.div`
  $${TYPOGRAPHY.boydRegular20}
  color: var(--color6);

  display: flex;
  align-items: center;
  margin-bottom: 12px;

  & svg {
    margin-right: 12px;
  }
`;

const StepParagraph = styled.div`
  ${TYPOGRAPHY.bodyRegular16}
  margin-left: 44px;
  color: var(--color9);
`;

const emojiPulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
`;

const EmojiContainer = styled.div`
  cursor: pointer;
  margin-right: 8px;
  &:hover {
    animation: ${emojiPulseAnimation} 0.2s ease-in normal;
  }
`;

const MoreInfo = styled(Link)`
  ${TYPOGRAPHY.captionRegular12}

  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-weight: 700;
  color: var(--color3);
  padding: 4px 6px;
  border: 2px solid var(--color14);
  border-radius: 8px;
  transition: 0.3s;

  &:hover {
    border: 2px solid var(--color3);
  }

  @media ${DEVICE.mobileL} {
    margin-top: 0;

    & > svg {
      margin: 0 0 -2px 4px;
    }
  } ;
`;

const StyledRightArrowIcon = styled(RightArrowIcon)`
  width: 12px;
  height: 12px;
  stroke: var(--color3);
  margin-left: 6px;
`;
