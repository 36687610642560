import { DEVICE, TYPOGRAPHY } from '@net0-calc/common/assets/styles';
import { CrossIcon } from '@net0-calc/common/assets/svg';
import React, { FC, useEffect, useRef, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { toast } from 'services';
import styled from 'styled-components/macro';
import { containsOnlyLatinChars } from 'utils';

type Suggestion = { name: string; country: string; locode: string };

// some search api
const SearchApi = {
  searchPort: async (value: string): Promise<Suggestion[]> => {
    return [{ name: value, country: value, locode: value }];
  },
};

interface SearchPortInputProps extends FieldRenderProps<string> {
  className?: string;
  isDisabled?: boolean;
  label?: string;
  placeholder?: string;
}

const useSearchPorts = ({ initialValue }: { initialValue: string }) => {
  const [value, setValue] = useState(initialValue);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldFetchData, setShouldFetchData] = useState(false);
  const [suggestions, setSuggestions] = useState<Suggestion[] | null>(null);
  const clearSuggestions = () => setSuggestions(null);

  useEffect(() => {
    if (!shouldFetchData) {
      return;
    }

    const minSearchValueLen = 2;
    const maxSearchValueLen = 25;
    if (value.length < minSearchValueLen) return;
    if (value.length > maxSearchValueLen) return;

    let didCancel = false;
    let timeoutId: NodeJS.Timeout | undefined;

    const doFetch = async () => {
      setIsLoading(true);
      try {
        const $suggestions = await SearchApi.searchPort(value);
        if (didCancel) return;

        setSuggestions($suggestions);
      } catch (error) {
        if (didCancel) return;
        toast.error((error as Error)?.message);
      } finally {
        setIsLoading(false);
      }
    };

    const debounceTimeMs = 300;
    setTimeout(doFetch, debounceTimeMs);

    return () => {
      didCancel = true;
      clearTimeout(timeoutId);
    };
  }, [value, shouldFetchData]);

  return {
    value,
    setValue: (value: string, shouldFetchData = true) => {
      setValue(value);
      setShouldFetchData(shouldFetchData);
    },
    suggestions,
    clearSuggestions,
    isLoading,
  };
};

export const SearchPortInput: FC<SearchPortInputProps> = ({ className, label, placeholder, input }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const portsSearcher = useSearchPorts({ initialValue: input.value });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const targetValue = event.currentTarget.value;
    if (targetValue.length === 0 || containsOnlyLatinChars(targetValue)) {
      portsSearcher.setValue(targetValue);
    }
  };

  const onSelectItem = (suggestion: Suggestion) => async () => {
    inputRef.current && inputRef.current.blur();

    const value = suggestion.locode;

    const shouldFetchData = false;
    portsSearcher.setValue(value, shouldFetchData);
    portsSearcher.clearSuggestions();

    input.onChange(value);
  };

  const handleClear = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const shouldFetchData = false;
    portsSearcher.setValue('', shouldFetchData);
    input.onChange(undefined);
  };

  const { suggestions } = portsSearcher;
  return (
    <Root className={className}>
      <StyledLabel>{label}</StyledLabel>
      <InputWrap>
        <StyledInput
          ref={inputRef}
          value={portsSearcher.value}
          onChange={handleChange}
          placeholder={placeholder}
          autoComplete="off"
        />
        {portsSearcher.value && (
          <ClearButton tabIndex={-1} type="button" onClick={handleClear}>
            <CrossIcon />
          </ClearButton>
        )}
        {portsSearcher.isLoading && (
          <SearchResultList>
            <SearchResultListItem>Loading...</SearchResultListItem>
          </SearchResultList>
        )}
        {suggestions && (
          <SearchResultList>
            {suggestions.length === 0 && <SearchEmptyResultItem>No results</SearchEmptyResultItem>}
            {suggestions.map(suggestion => {
              const { locode, name, country } = suggestion;

              return (
                <SearchResultListItem key={locode} onClick={onSelectItem(suggestion)}>
                  <strong>{locode}</strong> (<small>{country}</small>, <small>{name}</small>)
                </SearchResultListItem>
              );
            })}
          </SearchResultList>
        )}
      </InputWrap>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
`;

const StyledInput = styled.input`
  ${TYPOGRAPHY.bodyRegular16}
  width: 80%;
  color: var(--color6);
  outline: none;
`;

const InputWrap = styled.div`
  position: relative;
  width: 100%;
  height: 64px;
  padding: 18px;

  border-radius: 15px;
  border: 2px solid var(--color10);

  background-color: var(--color5);

  cursor: text;
  transition: all 0.2s;
  margin-bottom: 24px;

  @media ${DEVICE.laptop} {
    margin-bottom: 0;
  }

  &:focus-within {
    border: 2px solid var(--color2);
  }
`;

const SearchResultList = styled.ul`
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  z-index: 500;
  border-radius: 8px;
  box-shadow: 0px 5px 10px var(--color10);

  width: 100%;
  max-height: 230px;
  background-color: var(--color5);

  margin: 0;
  padding: 0;
  overflow: auto;
`;

const SearchResultListItem = styled.li`
  ${TYPOGRAPHY.bodyMedium20};
  color: var(--color6);

  width: 100%;
  padding: 16px;

  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid var(--color10);
  }

  &:hover {
    background-color: var(--color8);
  }
`;

const SearchEmptyResultItem = styled.li`
  ${TYPOGRAPHY.bodyMedium20};
  color: var(--color6);
  width: 100%;
  padding: 16px;
  text-align: center;
`;

const StyledLabel = styled.label`
  ${TYPOGRAPHY.bodyRegular16};
  display: block;
  margin-bottom: 8px;
  color: var(--color9);
`;

const ClearButton = styled.button`
  position: absolute;
  top: 18px;
  right: 18px;
  cursor: pointer;
`;
