import { useEffect } from 'react';

export function useOnOutsideClick(
  ref: React.MutableRefObject<HTMLElement | null | undefined>,
  callback: (event: Event) => void,
) {
  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (ref.current && event.target && !ref.current.contains(event.target as Node)) {
        callback(event);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}
