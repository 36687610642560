import { COLORS } from 'assets/colors';
import { CalculatorFormValues, Co2FootprintsVOs, Language } from 'common/types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store';
import { selectors } from 'store/ducks/dictionary';
import { Co2FootprintVO } from 'vo';
import { ListItem } from './components';

const useTodayLocalDateString = () => {
  const localesMapping: Record<Language, string> = {
    [Language.ENGLISH]: 'en-US',
    [Language.DEUTSCH]: 'de-DE',
    [Language.FRANÇAIS]: 'fr-FR',
    [Language.ITALIANO]: 'it-IT',
  };

  const currentLanguage = useAppSelector(selectors.selectLanguage);
  const currentLocale = localesMapping[currentLanguage];
  const todayLocaleDateString = new Date().toLocaleDateString(currentLocale, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });
  return todayLocaleDateString;
};

const translationKeyPrefix = 'emiratesPersonal.socialImageLayout';

type Props = {
  rootRef: React.RefObject<HTMLHtmlElement>;
  formValues: CalculatorFormValues;
  co2FootprintSumVO: Co2FootprintVO;
  co2FootprintsVOs: Co2FootprintsVOs;
};

const SocialImageLayout: FC<Props> = ({ rootRef, formValues, co2FootprintSumVO, co2FootprintsVOs }) => {
  const todayLocaleDateString = useTodayLocalDateString();

  const { t } = useTranslation();
  const travelToCityCo2FootprintVO = Co2FootprintVO.sum([co2FootprintsVOs.fromTo, co2FootprintsVOs.fromAirportToCity]);

  return (
    <html ref={rootRef}>
      <head>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap"
          rel="stylesheet"
        />
        <style>
          {`
            html,
            body,
            div,
            span,
            applet,
            object,
            iframe,
            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p,
            blockquote,
            pre,
            a,
            abbr,
            acronym,
            address,
            big,
            cite,
            code,
            del,
            dfn,
            em,
            img,
            ins,
            kbd,
            q,
            s,
            samp,
            small,
            strike,
            strong,
            sub,
            sup,
            tt,
            var,
            b,
            u,
            i,
            center,
            dl,
            dt,
            dd,
            ol,
            ul,
            li,
            fieldset,
            form,
            label,
            legend,
            table,
            caption,
            tbody,
            tfoot,
            thead,
            tr,
            th,
            td,
            article,
            aside,
            canvas,
            details,
            embed,
            figure,
            figcaption,
            footer,
            header,
            hgroup,
            menu,
            nav,
            output,
            ruby,
            section,
            summary,
            time,
            mark,
            audio,
            video {
              margin: 0;
              padding: 0;
              border: 0;
              font-size: 100%;
              font: inherit;
              vertical-align: baseline;
            }

            `}
        </style>
      </head>
      <body
        style={{
          width: '1080px',
          fontFamily: 'Poppins',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '20px',
        }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            border: `16px solid ${COLORS.color2}`,
            background: COLORS.color5,
            padding: '80px',
          }}>
          <h1
            style={{
              fontWeight: 800,
              fontSize: '60px',
              lineHeight: '90px',
              color: COLORS.color6,
              marginBottom: '106px',
              whiteSpace: 'pre-line',
            }}>
            <span dangerouslySetInnerHTML={{ __html: t(`${translationKeyPrefix}.title`) }}></span>
            <span style={{ color: COLORS.color2 }}> {co2FootprintSumVO.total.stringifiedValue}</span>
          </h1>
          <h2
            style={{
              fontWeight: 600,
              fontSize: '32px',
              lineHeight: '48px',
              color: COLORS.color6,
              marginBottom: '32px',
            }}>
            {t(`${translationKeyPrefix}.subtitle`)}
          </h2>
          <ul>
            <ListItem
              title={t(`${translationKeyPrefix}.co2FootprintList.travelAround`, { city: 'Dubai' })}
              value={co2FootprintsVOs.aroundCity.total.stringifiedValue}
              color={COLORS.chartColor2}
            />
            <ListItem
              title={t(`${translationKeyPrefix}.co2FootprintList.accommodation`)}
              value={co2FootprintsVOs.accomodation.total.stringifiedValue}
              color={COLORS.chartColor3}
            />
            <ListItem
              title={t(`${translationKeyPrefix}.co2FootprintList.travelFromTo`, {
                fromCity: formValues.fromLocation?.mainAddress,
                toCity: 'Dubai',
              })}
              value={travelToCityCo2FootprintVO.total.stringifiedValue}
              color={COLORS.chartColor4}
            />
          </ul>
          <table style={{ display: 'block', paddingTop: '220px' }}>
            <tr>
              <td style={{ width: '100%' }}>
                <div
                  style={{
                    display: 'inline-block',
                    fontWeight: 500,
                    fontSize: '20px',
                    lineHeight: '30px',
                    color: '#333',
                  }}>
                  <span style={{ color: '#797979' }}>{t(`${translationKeyPrefix}.footer.dateTitle`)}</span>{' '}
                  {todayLocaleDateString}
                </div>
              </td>
              <td>
                <img
                  style={{ display: 'inline-block', width: '200px', height: '82px', float: 'right' }}
                  src="https://net0-calculators-prod.s3.amazonaws.com/logo-monaco.png"
                  alt="monaco-logo-icon"
                  loading="lazy"
                />
              </td>
            </tr>
          </table>
        </div>
      </body>
    </html>
  );
};

export { SocialImageLayout };
