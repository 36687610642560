import { FC } from 'react';
import { toast } from 'services';
import { useTranslation } from 'react-i18next';
import { Button } from '@net0-calc/common/components/ui';
import { CopyLinkIcon } from '@net0-calc/common/assets/svg';

type Props = {
  answerId: string | null;
};
export const CopyResultLinkButton: FC<Props> = ({ answerId }) => {
  const { t } = useTranslation();
  const buttonTitle = t('general.copyResultsLink.title');

  const copyLink = () => {
    if (answerId) {
      navigator.clipboard.writeText(`${window.location.origin}/results/${answerId}`).then(() => {
        toast.success(t('common.receiveResults.toastMessages.resultLinkIsCopied'));
      });
    }
  };

  return (
    <Button
      icon={<CopyLinkIcon stroke="var(--color1)" />}
      onClick={copyLink}
      variant="secondary"
      title={buttonTitle}
      type="button"
    />
  );
};
