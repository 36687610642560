import { AirlineTicketClass, MainTransport } from 'common/types';
import { DistanceVO } from 'vo';
import { Co2FootprintVO } from 'vo/Co2FootprintVO';

const getCo2FootprintCoeffByAirlineTicketClass = (ticketClass: AirlineTicketClass) => {
  const mapping: Record<AirlineTicketClass, number> = {
    [AirlineTicketClass.ECONOMY]: 1.5,
    [AirlineTicketClass.BUSINESS]: 2.9,
    [AirlineTicketClass.FIRST_CLASS]: 4,
    [AirlineTicketClass.PRIVATE_JET]: 8,
  };
  return mapping[ticketClass];
};

const getCo2ePerKmByTransport = (transport: MainTransport) => {
  const mapping: Record<MainTransport, number> = {
    [MainTransport.WALK]: 0,
    [MainTransport.CYCLE]: 0,

    /**
     * taken from DEFRA Dataset "Business travel- land" tab
     * https://docs.google.com/spreadsheets/d/1_1fVicqbKRj3VOewv0SA9QyLj3DI5HFL/edit#gid=1284393118
     */
    [MainTransport.CAR]: 0.16637, // Medium car
    [MainTransport.BUS]: 0.10312, // Average local bus
    [MainTransport.TRAIN]: 0.03694, // National rail
    [MainTransport.ELECTRIC_CAR]: 0.28991, // Electro car

    // Taken from backend
    [MainTransport.HELICOPTER]: 9.8394, // Jet Fuel - Jet Fuel Aircraft

    // Taken from https://www.climatiq.io/explorer
    [MainTransport.AIRPLANE]: 0.25388726, // Domestic flight - with RF effect GHG Protocol
    [MainTransport.SHIP]: 0.111715929, // Ferry - average (all passenger) GHG Protocol
  };

  return mapping[transport];
};

type BaseParams = {
  peopleCount: number;
  distanceVO: DistanceVO;
};

type AirplaneParams = BaseParams & {
  ticketClass: AirlineTicketClass;
};

type ParamsByTransport<T> = T extends MainTransport.AIRPLANE ? AirplaneParams : BaseParams;

export default <T extends MainTransport>(transport: T, params: ParamsByTransport<T>) => {
  const { peopleCount, distanceVO } = params;

  const isAirplaneTransport = transport === MainTransport.AIRPLANE;
  const coeff = isAirplaneTransport
    ? getCo2FootprintCoeffByAirlineTicketClass((params as AirplaneParams).ticketClass)
    : 1;

  const perKm = getCo2ePerKmByTransport(transport);
  const perPerson = perKm * distanceVO.km * coeff;
  const total = perPerson * peopleCount;

  return Co2FootprintVO.create({ perPerson, total });
};
