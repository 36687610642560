import { DISTANCE_BETWEEN_MONAKO_AND_NICE_AIRPORT_KM } from 'common/constants/map-point';
import { FromAirportTransport } from 'common/types';
import { Co2FootprintVO } from 'vo';

// TODO: think about move this logic to calculate-movement.ts

const getCo2ePerKmByTransport = (transport: FromAirportTransport) => {
  /**
   * emission factors taken from DEFRA Dataset "Business travel- land" tab
   * https://docs.google.com/spreadsheets/d/1_1fVicqbKRj3VOewv0SA9QyLj3DI5HFL/edit#gid=1284393118
   */
  const mapping: Record<FromAirportTransport, number> = {
    [FromAirportTransport.TAXI]: 0.14549, // Regular taxi passenger.km
    [FromAirportTransport.BUS]: 0.10312, // Average local bus
    [FromAirportTransport.TRAIN]: 0.03694, // National rail

    // taken from backend (search in IDE)
    [FromAirportTransport.HELICOPTER]: 9.8394, // Jet Fuel - Jet Fuel Aircraft
  };

  return mapping[transport];
};

type Params = {
  peopleCount: number;
  transport: FromAirportTransport;
};

export default (params: Params) => {
  const { peopleCount, transport } = params;

  const perKm = getCo2ePerKmByTransport(transport);
  const perPerson = perKm * DISTANCE_BETWEEN_MONAKO_AND_NICE_AIRPORT_KM;
  const total = perPerson * peopleCount;

  return Co2FootprintVO.create({ perPerson, total });
};
