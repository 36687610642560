import * as React from 'react';
import { SVGProps } from 'react';

const SvgLargeElectroCar = (props: SVGProps<SVGSVGElement>) => (
  <svg width={61} height={60} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#large-electro-car_svg__a)" stroke="#B11425" strokeLinecap="round" strokeLinejoin="round">
      <path
        d="M48 42.5h2.5A2.5 2.5 0 0 0 53 40v-8.05a2.5 2.5 0 0 0-1.9-2.5L43 27.5l-6.75-9a2.5 2.5 0 0 0-2-1h-14.7a2.5 2.5 0 0 0-2.25 1.375L13 27.5l-3.6 1.775a2.5 2.5 0 0 0-1.4 2.25V40a2.5 2.5 0 0 0 2.5 2.5H13"
        strokeWidth={2}
      />
      <path
        d="M23 42.5h15m-15 0a5 5 0 1 1-10 0 5 5 0 0 1 10 0Zm5-25v10m15 0H13m17.5-10h-5m17.5 20a5 5 0 1 0 0 10 5 5 0 0 0 0-10Z"
        strokeWidth={2}
      />
      <path d="M25.166 8 28.5 9.333 27.833 14l4-6-2.666-1.333L29.832 2l-4.666 6Z" strokeWidth={1.5} />
    </g>
    <defs>
      <clipPath id="large-electro-car_svg__a">
        <path fill="#fff" transform="translate(.5)" d="M0 0h60v60H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgLargeElectroCar;
