/**
 * const list1 = ['a', 'b'];
 * hasOnly(list1, ['a', 'b']) // true;
 * hasOnly(list1, ['a']) // false;
 * hasOnly(list1, ['b']) // false;
 *
 * const list2 = ['a'];
 * hasOnly(list2, ['a', 'b']) // true;
 * hasOnly(list2, ['a']) // true;
 * hasOnly(list2, ['b']) // false;
 *
 * const list3 = ['a', 'b', 'c'];
 * hasOnly(list3, ['a', 'b']) // false;
 * hasOnly(list3, ['a']) // false;
 * hasOnly(list3, ['b']) // false;
 * hasOnly(list3, ['a', 'b', 'c']) // true;
 */

export const hasOnly = <T>(list: T[], elementsToCheck: T[]): boolean => {
  const listLen = list.length;
  const elementsToCheckLen = elementsToCheck.length;

  if (listLen > elementsToCheckLen) {
    return false;
  }

  return list.every(element => elementsToCheck.includes(element));
};
