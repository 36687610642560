import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useEffect, useState } from 'react';
import { useFetchAnswer, useFormValues } from '../MainPage/hooks';
import { Co2FootprintVO } from 'vo';
import { TipsToReduce, TopContainer } from '../MainPage/components/Result/components';
import { Co2FootprintsVOs } from '../../common/types';
import { MainLayout } from 'layouts';
import { Loader } from '@net0-calc/common/components/ui';
import { DownloadMethodology } from '@net0-calc/common/components';
import { DEVICE } from '@net0-calc/common/assets/styles';

export const ResultsPage = () => {
  const { id } = useParams<{ id: string }>();

  const [answer, { isLoading }] = useFetchAnswer(id ?? null);

  const { formValues, setFormValues } = useFormValues();

  const [co2FootprintsVOs, setCo2FootprintsVOs] = useState<Co2FootprintsVOs>();
  const co2FootprintSumVO = Co2FootprintVO.sum(Object.values(co2FootprintsVOs || {}));

  useEffect(() => {
    if (answer) {
      const { formValues, co2Footprint } = answer.answers;
      const { fromTo, fromAirportToCity, aroundCity, accomodation } = co2Footprint;

      setFormValues(formValues);
      setCo2FootprintsVOs({
        fromTo: Co2FootprintVO.create({ perPerson: fromTo.perPersonKg, total: fromTo.totalKg }),
        accomodation: Co2FootprintVO.create({ perPerson: accomodation.perPersonKg, total: accomodation.totalKg }),
        fromAirportToCity: Co2FootprintVO.create({
          perPerson: fromAirportToCity.perPersonKg,
          total: fromAirportToCity.totalKg,
        }),
        aroundCity: Co2FootprintVO.create({ perPerson: aroundCity.perPersonKg, total: aroundCity.totalKg }),
      });
    }
  }, [answer]);

  if (isLoading) {
    return (
      <LoaderWrapper>
        <Loader size={80} color="var(--color2)" />
      </LoaderWrapper>
    );
  }

  return (
    <MainLayout isResultPage isBackButtonVisible={false}>
      <Root>
        <StyledDownloadMethodology />
        {co2FootprintsVOs && (
          <TopContainer
            formValues={formValues}
            co2FootprintSumVO={co2FootprintSumVO}
            co2FootprintsVOs={co2FootprintsVOs}
          />
        )}
        {/* <Factors formValues={formValues} /> */}
        <StyledTipsToReduce />
      </Root>
    </MainLayout>
  );
};
const Root = styled.div`
  width: 100%;
  min-height: 1200px;
`;
const LoaderWrapper = styled.div`
  display: flex;
  min-height: 100%;
  align-items: center;
  justify-content: center;
`;

const StyledTipsToReduce = styled(TipsToReduce)`
  margin-bottom: 0;
`;

const StyledDownloadMethodology = styled(DownloadMethodology)`
  width: 100%;
  margin-bottom: 20px;

  @media ${DEVICE.laptop} {
    display: none;
  }
`;
