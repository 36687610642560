import { FC } from 'react';
import { FieldInputProps } from 'react-final-form';
import { MobileModal } from './MobileModal';
import { DesktopModal } from './DesktopModal';
import { SIZE } from '../../../../../assets/styles';
import { useGetWindowSize } from '../../../../../hooks';

interface CalendarModal extends FieldInputProps<[Date, Date]> {
  isVisible: boolean;
  onCloseModal: () => void;
  selectedInputLabel: string;
  locale: string;
}

export const CalendarModal: FC<CalendarModal> = ({ isVisible, onCloseModal, selectedInputLabel, ...props }) => {
  const { width } = useGetWindowSize();

  return width < SIZE.tablet ? (
    <MobileModal isVisible={isVisible} onCloseModal={onCloseModal} selectedInputLabel={selectedInputLabel} {...props} />
  ) : (
    <DesktopModal isVisible={isVisible} onCloseModal={onCloseModal} {...props} />
  );
};
