import { CalculatorFormValues, MainTransport, StayingOvernight, StepName } from 'common/types';
import { useState } from 'react';
import { Co2FootprintVO, DistanceVO } from 'vo';
import * as utils from 'utils';
import { MONACO_MAP_POINT, NICE_MAP_POINT } from 'common/constants/map-point';
import { co2FootprintCalculator } from 'co2-footprint-calculator';

type Params = {
  formValues: CalculatorFormValues;
};

export const useStepCalculationHandlers = ({ formValues }: Params) => {
  const values = formValues as Required<CalculatorFormValues>;
  const [distanceVO, setDistanceVO] = useState(DistanceVO.create(0, 'km'));
  const [co2FootprintsVOs, setCo2FootprintsVOs] = useState({
    fromTo: Co2FootprintVO.create({ perPerson: 0, total: 0 }),
    fromAirportToCity: Co2FootprintVO.create({ perPerson: 0, total: 0 }),
    accomodation: Co2FootprintVO.create({ perPerson: 0, total: 0 }),
    aroundCity: Co2FootprintVO.create({ perPerson: 0, total: 0 }),
  });
  const updateCo2FootprintsVOs = (statePart: Partial<typeof co2FootprintsVOs>) => {
    setCo2FootprintsVOs(currentState => ({
      ...currentState,
      ...statePart,
    }));
  };

  const stepCalculationHandlers: Record<StepName, () => void> = {
    [StepName.WELCOME]: utils.noop,
    [StepName.TRIP_DURATION]: utils.noop,
    [StepName.TRAVELERS]: utils.noop,
    [StepName.COUNTRY_AND_CITY]: () => {
      const { mapPoint: fromMapPoint } = values.fromLocation;
      const distanceKm = utils.calculateKmDistanceBetweenMapPoints(fromMapPoint, MONACO_MAP_POINT);
      const distanceVO = DistanceVO.create(distanceKm);
      setDistanceVO(distanceVO);
    },
    [StepName.TRANSPORT]: () => {
      const { mainTransport, peopleCount } = values;
      const isAirplaneTransport = mainTransport === MainTransport.AIRPLANE;
      if (isAirplaneTransport) return;
      const co2Footprint = co2FootprintCalculator.calculateMovement(mainTransport, {
        distanceVO,
        peopleCount,
      });
      updateCo2FootprintsVOs({ fromTo: co2Footprint });
    },
    [StepName.AIRLINE_CLASS_OF_SERVICE]: () => {
      const { peopleCount, airlineTicketClass } = values;
      const { mapPoint: fromMapPoint } = values.fromLocation;
      const distanceToNiceKm = utils.calculateKmDistanceBetweenMapPoints(fromMapPoint, NICE_MAP_POINT);
      const distanceToNiceVO = DistanceVO.create(distanceToNiceKm);
      const co2Footprint = co2FootprintCalculator.calculateMovement(MainTransport.AIRPLANE, {
        peopleCount,
        distanceVO: distanceToNiceVO,
        ticketClass: airlineTicketClass,
      });
      updateCo2FootprintsVOs({ fromTo: co2Footprint });
    },
    [StepName.GETTING_INTO_CITY_FROM_AIRPORT]: () => {
      const { peopleCount, fromAirportTransport } = values;
      const co2Footprint = co2FootprintCalculator.calculateIntoCityFromAirportMovement({
        peopleCount,
        transport: fromAirportTransport,
      });
      updateCo2FootprintsVOs({ fromAirportToCity: co2Footprint });
    },
    [StepName.PLACE_TO_STAY]: () => {
      const { tripDuration, peopleCount, stayingPlace, stayingOvernight } = values;
      if (stayingOvernight === StayingOvernight.NO) {
        const co2Footprint = Co2FootprintVO.create({ total: 0, perPerson: 0 });
        updateCo2FootprintsVOs({ accomodation: co2Footprint });
        return;
      }
      const tripDaysCount = utils.calculateTripDaysCount(...tripDuration);
      const co2Footprint = co2FootprintCalculator.calculateAccomodation({
        peopleCount,
        tripDaysCount,
        stayingPlace,
      });
      updateCo2FootprintsVOs({ accomodation: co2Footprint });
    },
    [StepName.GETTING_AROUND_CITY]: () => {
      const { tripDuration, peopleCount, aroundCityTransports } = values;
      const tripDaysCount = utils.calculateTripDaysCount(...tripDuration);

      const co2FootprintsVOs = aroundCityTransports.map(transport => {
        return co2FootprintCalculator.calculateAroundCityMovement({
          transport,
          peopleCount,
          tripDaysCount,
        });
      });
      const avgCo2Footrpint = Co2FootprintVO.avg(co2FootprintsVOs);
      updateCo2FootprintsVOs({ aroundCity: avgCo2Footrpint });
    },
  };

  const handleStepCalculations = (currentStepName: StepName) => {
    const handle = stepCalculationHandlers[currentStepName];
    handle();
  };

  return { distanceVO, co2FootprintsVOs, handleStepCalculations };
};
