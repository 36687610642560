import { TopContainer, TipsToReduce, ReceiveResult, ActionButtons } from './components';
import styled from 'styled-components/macro';
import { Co2FootprintVO } from 'vo';
import { CalculatorFormValues, Co2FootprintsVOs } from 'common/types';
import { DEVICE } from '@net0-calc/common/assets/styles';

type Props = {
  formValues: CalculatorFormValues;
  co2FootprintSumVO: Co2FootprintVO;
  co2FootprintsVOs: Co2FootprintsVOs;
  answerId?: string;
};

export const Result = ({ formValues, answerId = '', co2FootprintSumVO, co2FootprintsVOs }: Props) => {
  return (
    <Root>
      <TopContainer co2FootprintSumVO={co2FootprintSumVO} co2FootprintsVOs={co2FootprintsVOs} formValues={formValues} />
      {/* <Factors formValues={formValues} /> */}
      <TipsToReduce />
      <ActionButtons
        formValues={formValues}
        co2FootprintSumVO={co2FootprintSumVO}
        co2FootprintsVOs={co2FootprintsVOs}
        answerId={answerId}
      />
      <ReceiveResult answerId={answerId} />
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const CertificateImageContainer = styled.div`
  right: 50%;
  bottom: 0;
  padding: 20px 0 0;
  order: 6;
  margin: 0 auto;
  cursor: pointer;

  & > img {
    width: 62px;
    height: 62px;
  }

  @media ${DEVICE.laptop} {
    position: absolute;
    transform: translateX(50%);
    right: 0;
    padding: 0;
    padding-right: 80px;

    & > img {
      width: 100px;
      height: 100px;
    }
  }
`;
