import 'react-toastify/dist/ReactToastify.css';
import { Provider as StoreProvider } from 'react-redux';
import { MainPage, ResultsPage } from 'pages';
import { store } from 'store';
import { Suspense } from 'react';
import styled from 'styled-components';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Loader } from '@net0-calc/common/components/ui';
import { StyledToastContainer } from '@net0-calc/common/components/ui/Toast/Toast';
import './assets/colors.css';

function App() {
  return (
    <StoreProvider store={store}>
      <CookiesProvider>
        <Suspense
          fallback={
            <LoaderWrapper>
              <Loader size={80} color="var(--color2)" />
            </LoaderWrapper>
          }>
          <BrowserRouter>
            <Routes>
              <Route path="/*">
                <Route index element={<MainPage />} />
                <Route path="results/:id" element={<ResultsPage />} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </Suspense>
        <StyledToastContainer />
      </CookiesProvider>
    </StoreProvider>
  );
}

export default App;

const LoaderWrapper = styled.div`
  display: flex;
  min-height: 100%;
  align-items: center;
  justify-content: center;
`;
