export const downloadUrl = (fileUrl: string, filename: string): void => {
  const anchor = document.createElement('a');
  document.body.appendChild(anchor);

  anchor.setAttribute('style', 'display: none');
  anchor.href = fileUrl;
  anchor.download = filename;
  anchor.click();

  document.body.removeChild(anchor);
};
