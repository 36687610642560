import { CalculatorFormValues, MainTransport } from 'common/types';
import * as validators from 'validators';
import { FC } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { WizardPageProps } from '../../WizardForm/WizardForm';
import { DistanceVO } from 'vo';
import {
  LargeBusIcon,
  LargeCarIcon,
  LargeCycleIcon,
  LargeElectroCar,
  LargeHelicopterIcon,
  LargePlaneIcon,
  LargeShipIcon,
  LargeTrainIcon,
  LargeWalkIcon,
} from '@net0-calc/common/assets/svg';
import { DEVICE } from '@net0-calc/common/assets/styles';
import { CardRadioButton, Hints, StepDescription, StepTitle } from '@net0-calc/common/components/ui';

type TransportPageProps = WizardPageProps<CalculatorFormValues> & { distanceVO: DistanceVO };

const optionsTranslationPrefix = 'general.options.transport';
const pageTranslationPrefix = 'emiratesPersonal.pages.transport';

const useOptions = ({ distanceVO }: { distanceVO: DistanceVO }) => {
  const { t } = useTranslation();

  if (distanceVO.km <= 300) {
    return [
      ...(distanceVO.km <= 10
        ? [
            { icon: <LargeWalkIcon />, title: t(`${optionsTranslationPrefix}.walk`), value: MainTransport.WALK },
            { icon: <LargeCycleIcon />, title: t(`${optionsTranslationPrefix}.cycle`), value: MainTransport.CYCLE },
          ]
        : []),
      { icon: <LargeCarIcon />, title: t(`${optionsTranslationPrefix}.car`), value: MainTransport.CAR },
      {
        icon: <LargeElectroCar />,
        title: t(`${optionsTranslationPrefix}.electricCar`),
        value: MainTransport.ELECTRIC_CAR,
      },
      { icon: <LargeTrainIcon />, title: t(`${optionsTranslationPrefix}.train`), value: MainTransport.TRAIN },
      { icon: <LargeBusIcon />, title: t(`${optionsTranslationPrefix}.bus`), value: MainTransport.BUS },
      {
        icon: <LargeHelicopterIcon />,
        title: t(`${optionsTranslationPrefix}.helicopter`),
        value: MainTransport.HELICOPTER,
      },
    ];
  }

  return [
    ...(distanceVO.km <= 2000
      ? [
          {
            icon: <LargeCarIcon />,
            title: t(`${optionsTranslationPrefix}.car`),
            value: MainTransport.CAR,
          },
          {
            icon: <LargeElectroCar />,
            title: t(`${optionsTranslationPrefix}.electricCar`),
            value: MainTransport.ELECTRIC_CAR,
          },
        ]
      : []),
    { icon: <LargePlaneIcon />, title: t(`${optionsTranslationPrefix}.airplane`), value: MainTransport.AIRPLANE },
    { icon: <LargeShipIcon />, title: t(`${optionsTranslationPrefix}.ship`), value: MainTransport.SHIP },
    { icon: <LargeTrainIcon />, title: t(`${optionsTranslationPrefix}.train`), value: MainTransport.TRAIN },
  ];
};

export const TransportPage: FC<TransportPageProps> = ({ distanceVO, values }) => {
  const { t } = useTranslation();

  const options = useOptions({ distanceVO });

  return (
    <Root>
      <StepTitle>{t(`${pageTranslationPrefix}.title`)}</StepTitle>
      <StepDescription>{t(`${pageTranslationPrefix}.subtitle`)}</StepDescription>
      <CardsGroup>
        {options.map(({ value, icon, title }) => (
          <Field key={value} name="mainTransport" type="radio" value={value} validate={validators.isRequired}>
            {({ input }) => <CardRadioButton id={`${value}-option`} icon={icon} title={title} {...input} />}
          </Field>
        ))}
      </CardsGroup>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
`;

const CardsGroup = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 13px;
  margin-bottom: 20px;
  @media ${DEVICE.tablet} {
    margin-bottom: 50px;
    justify-content: flex-start;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, 195px);
  }
`;

const StyledHints = styled(Hints)`
  margin-bottom: 60px;

  @media ${DEVICE.tablet} {
    margin-bottom: 70px;
  }
`;
