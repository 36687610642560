export const isCheckboxRequired = (value: unknown) => {
  const message = 'Required';

  if (!value) {
    return message;
  }

  if (value instanceof Array) {
    if (value.length === 0) {
      return message;
    }
  }

  return undefined;
};
