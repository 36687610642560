import { CalculatorFormValues, FromAirportTransport } from 'common/types';
import * as validators from 'validators';
import { FC, useMemo } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { WizardPageProps } from '../../WizardForm/WizardForm';
import { LargeBusIcon, LargeCarIcon, LargeHelicopterIcon, LargeTrainIcon } from '@net0-calc/common/assets/svg';
import { DEVICE } from '@net0-calc/common/assets/styles';
import { Hints, StepDescription, StepTitle, CardRadioButton } from '@net0-calc/common/components/ui';

const optionsTranslationPrefix = 'general.options.transport';
const pageTranslationPrefix = 'emiratesPersonal.pages.gettingIntoCity';

const { BUS, HELICOPTER, TAXI, TRAIN } = FromAirportTransport;

export const GettingIntoCityPage: FC<WizardPageProps<CalculatorFormValues>> = ({ values }) => {
  const { t } = useTranslation();

  const options = [
    { value: BUS, title: t(`${optionsTranslationPrefix}.bus`), icon: <LargeBusIcon /> },
    { value: TAXI, title: t(`${optionsTranslationPrefix}.taxi`), icon: <LargeCarIcon /> },
    { value: TRAIN, title: t(`${optionsTranslationPrefix}.train`), icon: <LargeTrainIcon /> },
    {
      value: HELICOPTER,
      title: t(`${optionsTranslationPrefix}.helicopter`),
      icon: <LargeHelicopterIcon />,
    },
  ];
  return (
    <Root>
      <StepTitle>{t(`${pageTranslationPrefix}.title`)}</StepTitle>
      <StepDescription>{t(`${pageTranslationPrefix}.subtitle`)}</StepDescription>
      <CardsGroup>
        {options.map(({ title, value, icon }) => (
          <Field key={value} name="fromAirportTransport" type="radio" value={value} validate={validators.isRequired}>
            {({ input }) => <CardRadioButton id={`${value}-option`} icon={icon} title={title} {...input} />}
          </Field>
        ))}
      </CardsGroup>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
`;

const CardsGroup = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(2, 165px);
  grid-gap: 13px;
  margin-bottom: 20px;

  @media ${DEVICE.tablet} {
    justify-content: flex-start;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, 195px);
    margin-bottom: 20px;
  }
`;

const StyledHints = styled(Hints)`
  margin-bottom: 61px;

  @media ${DEVICE.tablet} {
    margin-bottom: 70px;
  }
`;
