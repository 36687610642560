import * as React from 'react';
import { SVGProps } from 'react';

const SvgLargeHelicopterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={60} height={60} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g
      clipPath="url(#large-helicopter-icon_svg__a)"
      stroke="#B11425"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round">
      <path d="M51.825 33H32a2.5 2.5 0 0 1-2.5-2.5V18" />
      <path d="M44.5 8h-30 30ZM52 53H17h35ZM29.5 18V8v10Zm-20 0h28.825a10 10 0 0 1 8.95 5.525L52 33v7.5a2.5 2.5 0 0 1-2.5 2.5H29.2a10 10 0 0 1-9.475-6.85l-2.15-6.45A2.5 2.5 0 0 0 15.2 28H9.5A2.5 2.5 0 0 1 7 25.5v-5A2.5 2.5 0 0 1 9.5 18Zm30 35h-10V43h10v10Z" />
    </g>
    <defs>
      <clipPath id="large-helicopter-icon_svg__a">
        <path fill="#fff" transform="matrix(-1 0 0 1 60 0)" d="M0 0h60v60H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgLargeHelicopterIcon;
