import { StayingPlace } from 'common/types';
import { Co2FootprintVO } from 'vo/Co2FootprintVO';

// taken from https://www.climatiq.io/explorer?category=Accommodation&region=FR
const FRANCE_RERSON_PER_NIGHT_CO2E_KG = 6.5;

/**
 * These values calculated by hand using this site https://www.hotelfootprints.org/
 * (Select France, and choose hotels in "select options" - results will be below)
 */
const EMISSION_COEFFICIENT_BY_STAYING_PLACE: Record<StayingPlace, number> = {
  [StayingPlace.HOTEL_2_STARS]: 0.33,
  [StayingPlace.HOTEL_3_STARS]: 0.5,
  [StayingPlace.HOTEL_4_STARS]: 1.14,
  [StayingPlace.HOTEL_5_STARS]: 1.86,
  [StayingPlace.PRIVATE_ACCOMODATION]: 0.2, // taken from the head
};

type Params = {
  peopleCount: number;
  tripDaysCount: number;
  stayingPlace: StayingPlace;
};

export default (params: Params) => {
  const { peopleCount, tripDaysCount, stayingPlace } = params;
  const coeff = EMISSION_COEFFICIENT_BY_STAYING_PLACE[stayingPlace];

  const perPerson = FRANCE_RERSON_PER_NIGHT_CO2E_KG * coeff * tripDaysCount;
  const total = perPerson * peopleCount;

  return Co2FootprintVO.create({ perPerson, total });
};
