import * as React from 'react';
import { SVGProps } from 'react';

const SvgBusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#bus-icon_svg__a)">
      <path
        d="M16.9 21H7.1C5.4 21 4 19.61 4 17.9V4.1C4 2.4 5.39 1 7.1 1h9.8C18.6 1 20 2.39 20 4.1v13.8c0 1.71-1.39 3.1-3.1 3.1Z"
        stroke="#BCBBBB"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5 12h-13c-.83 0-1.5-.67-1.5-1.5v-2C4 7.67 4.67 7 5.5 7h13c.83 0 1.5.67 1.5 1.5v2c0 .83-.67 1.5-1.5 1.5Z"
        stroke="#BCBBBB"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.495 16.7h.008-.008Z" fill="#BCBBBB" />
      <path d="M8.495 16.7h.008" stroke="#BCBBBB" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.495 16.7h.009-.01Z" fill="#BCBBBB" />
      <path d="M15.495 16.7h.009" stroke="#BCBBBB" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.5 4h5" stroke="#BCBBBB" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5 21h3v1.5a1.5 1.5 0 0 1-3 0V21ZM16 21h3v1.5a1.5 1.5 0 0 1-3 0V21Z" fill="#BCBBBB" />
    </g>
    <defs>
      <clipPath id="bus-icon_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgBusIcon;
