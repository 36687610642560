import * as React from 'react';
import { SVGProps } from 'react';

const SvgLargeShipIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={61} height={60} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M51.55 30.925c2.175.875 3.525 3.45 3.025 5.725L53.55 41.3c-1.775 8-8.05 13.7-17.1 13.7h-11.9c-9.05 0-15.325-5.7-17.1-13.7l-1.025-4.65c-.5-2.275.85-4.85 3.025-5.725L13 29.5l13.775-5.525c2.4-.95 5.05-.95 7.45 0L48 29.5l3.55 1.425ZM30.5 55V25"
      stroke="#B11425"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M48 20v9.5l-13.775-5.525c-2.4-.95-5.05-.95-7.45 0L13 29.5V20c0-4.125 3.375-7.5 7.5-7.5h20c4.125 0 7.5 3.375 7.5 7.5ZM36.75 12.5h-12.5v-5c0-1.375 1.125-2.5 2.5-2.5h7.5c1.375 0 2.5 1.125 2.5 2.5v5Z"
      stroke="#B11425"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgLargeShipIcon;
