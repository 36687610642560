import { CalculatorFormValues, StepName } from 'common/types';
import { MainLayout } from 'layouts';
import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Result, WizardForm } from './components';
import { gsap } from 'gsap';
import { Co2FootprintVO } from 'vo';
// import { CO2Footprint } from 'components';
import { useSteps, useFormValues, useStepCalculationHandlers, useFetchAnswer } from './hooks';
import { selectors, useAppSelector } from 'store';
import * as utils from 'utils';
import { Loader } from '@net0-calc/common/components/ui';
import { DEVICE } from '@net0-calc/common/assets/styles';
import { StepBar } from '@net0-calc/common/components';
import { api } from '@pwc-travel/api';

export const MainPage = () => {
  const isDictionaryLoading = useAppSelector(selectors.dictionary.selectIsDictionaryLoading);
  const [isResultPageSelected, setIsResultPageSelected] = useState(false);

  const { formValues, setFormValues } = useFormValues();
  const { co2FootprintsVOs, distanceVO, handleStepCalculations } = useStepCalculationHandlers({ formValues });
  const [steps, { changeCurrentStepIndex, currentStepIndex, currentStepName, isLastStep }] = useSteps({
    formValues,
    distanceVO,
  });
  const co2FootprintSumVO = Co2FootprintVO.sum(Object.values(co2FootprintsVOs));

  const sharedAnswerId = utils.getFromSearchString('answerId');
  const [answerId, setAnswerId] = useState<string>();
  const [answer, { isLoading: isAnswerLoading }] = useFetchAnswer(sharedAnswerId);

  const sendAnswers = () => {
    api
      .sendAnswers({
        answers: {
          locationOrigin: window.location.origin,
          formValues,
          co2Footprint: {
            sum: {
              totalKg: co2FootprintSumVO.total.kg,
              perPersonKg: co2FootprintSumVO.perPerson.kg,
            },
            fromTo: {
              totalKg: co2FootprintsVOs.fromTo.total.kg,
              perPersonKg: co2FootprintsVOs.fromTo.perPerson.kg,
            },
            fromAirportToCity: {
              totalKg: co2FootprintsVOs.fromAirportToCity.total.kg,
              perPersonKg: co2FootprintsVOs.fromAirportToCity.perPerson.kg,
            },
            accomodation: {
              totalKg: co2FootprintsVOs.accomodation.total.kg,
              perPersonKg: co2FootprintsVOs.accomodation.perPerson.kg,
            },
            aroundCity: {
              totalKg: co2FootprintsVOs.aroundCity.total.kg,
              perPersonKg: co2FootprintsVOs.aroundCity.perPerson.kg,
            },
          },
        },
      })
      .then(({ data }) => {
        setAnswerId(data);
      })
      .catch(console.log);
  };

  useEffect(() => {
    if (answer) {
      setFormValues(answer.answers.formValues);
    }
  }, [answer]);

  const isLoading = isDictionaryLoading || isAnswerLoading;
  if (isLoading) {
    return (
      <LoaderWrapper>
        <Loader size={80} color="var(--color2)" />
      </LoaderWrapper>
    );
  }

  const handleStepSubmit = async () => {
    handleStepCalculations(currentStepName);

    if (isLastStep) {
      setIsResultPageSelected(true);
      sendAnswers();
      return;
    }

    gsap
      .fromTo(
        '#step-icon-' + currentStepIndex,
        {
          scaleX: 2.5,
          scaleY: 2.5,
          x: '-10px',
          y: '-10px',
        },
        {
          scaleX: 1,
          scaleY: 1,
          x: '0px',
          y: '0px',
          duration: 0.95,
        },
      )
      .then(() => {
        changeCurrentStepIndex(current => current + 1);
      });
  };

  const handleBackButtonClick = () => {
    if (isResultPageSelected) {
      setIsResultPageSelected(false);
      changeCurrentStepIndex(steps.length - 1);
    } else {
      changeCurrentStepIndex(currentStepIndex - 1);
    }
  };

  return (
    <MainLayout
      isBackButtonVisible={currentStepIndex > 0 || isResultPageSelected}
      isResultPage={isResultPageSelected}
      onPrevStep={handleBackButtonClick}>
      {isResultPageSelected && (
        <Result
          answerId={answerId}
          formValues={formValues}
          co2FootprintSumVO={co2FootprintSumVO}
          co2FootprintsVOs={co2FootprintsVOs}
        />
      )}
      {!isResultPageSelected && (
        <PageWrap>
          <LeftContainer>
            <StepBar<CalculatorFormValues, StepName>
              steps={steps}
              currentStepIndex={currentStepIndex}
              onStepClick={changeCurrentStepIndex}
            />
          </LeftContainer>
          <MiddleContainer>
            <WizardForm<CalculatorFormValues>
              initialValues={formValues}
              currentPageIndex={currentStepIndex}
              onStepSubmit={handleStepSubmit}
              co2FootprintSumVO={co2FootprintSumVO}
              onChangeFormSpy={state => setFormValues(state.values)}
              pages={steps.map(({ page }) => page)}
            />
          </MiddleContainer>
          {/* <RightContainer>
            <CO2Footprint co2FootprintSumVO={co2FootprintSumVO} />
          </RightContainer> */}
        </PageWrap>
      )}
    </MainLayout>
  );
};

const PageWrap = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  @media ${DEVICE.laptop} {
    display: grid;
    justify-content: space-between;
    grid-template-columns: minmax(230px, 326px) minmax(500px, 628px) minmax(120px, 150px);
    grid-template-rows: 1fr;
  }
  @media ${DEVICE.desktop} {
    justify-content: flex-start;
    grid-template-columns: minmax(230px, 326px) minmax(500px, 628px) minmax(120px, 193px);
  }
`;

const LeftContainer = styled.div`
  background-color: var(--color5);
  min-height: 40px;
  margin-bottom: 24px;
  z-index: 200;

  @media ${DEVICE.laptop} {
    display: block;
    width: 100%;
    height: 100%;
    min-height: auto;
    margin-bottom: 0;
  }

  @media ${DEVICE.desktop} {
    margin-right: 126px;
  }
`;

const MiddleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const LoaderWrapper = styled.div`
  display: flex;
  min-height: 100%;
  align-items: center;
  justify-content: center;
`;

// const RightContainer = styled.div`
//   display: none;
//   background-color: var(--color5);
//   z-index: 200;

//   @media ${DEVICE.laptop} {
//     display: block;
//     width: 100%;
//   }

//   @media ${DEVICE.desktop} {
//     margin-left: 133px;
//   }
// `;
