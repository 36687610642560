import { DEVICE, TYPOGRAPHY } from '@net0-calc/common/assets/styles';
import { FC, useMemo } from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { formatWeightValue } from 'utils';
import { Co2FootprintVO } from 'vo';

interface ChartDonutSectionProps {
  fromAddress: string;
  co2FootprintSumVO: Co2FootprintVO;
  co2FootprintsVOs: {
    fromTo: Co2FootprintVO;
    fromAirportToCity: Co2FootprintVO;
    accomodation: Co2FootprintVO;
    aroundCity: Co2FootprintVO;
  };
  className?: string;
}

export const ChartDonutSection: FC<ChartDonutSectionProps> = ({
  fromAddress,
  co2FootprintSumVO,
  co2FootprintsVOs,
  className,
}) => {
  const { t } = useTranslation();

  const co2FootprintLevel = useMemo(() => {
    const co2FootprintKg = co2FootprintSumVO.perPerson.kg;
    if (co2FootprintKg <= 300) {
      return 'Low';
    }
    if (co2FootprintKg >= 1500) {
      return 'High';
    }
    return 'Average';
  }, [co2FootprintSumVO]);

  const co2footPrintLevelNames = {
    ['Low']: t('emiratesPersonal.pages.result.footprintLevel.low'),
    ['Average']: t('emiratesPersonal.pages.result.footprintLevel.average'),
    ['High']: t('emiratesPersonal.pages.result.footprintLevel.high'),
  };

  const travelToMonacoCo2FootpintVO = Co2FootprintVO.sum([co2FootprintsVOs.fromTo, co2FootprintsVOs.fromAirportToCity]);

  return (
    <Root className={className}>
      <StyledChart
        options={{
          states: {
            hover: {
              filter: {
                type: 'none',
              },
            },
          },
          stroke: { width: 0 },
          colors: ['var(--chart-color2)', 'var(--chart-color3)', 'var(--chart-color4)'],
          legend: {
            show: false,
          },
          tooltip: {
            enabled: true,
            y: {
              formatter: (value: number) => {
                return formatWeightValue(value);
              },
            },
          },
          labels: [
            t('emiratesPersonal.pages.result.topContainer.marks.travelAround'),
            t('emiratesPersonal.pages.result.topContainer.marks.accommodation'),
            t('emiratesPersonal.pages.result.topContainer.marks.travelTo', { fromAddress }),
          ],
          dataLabels: {
            enabled: false,
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  fontFamily: 'Poppins',
                  name: {
                    show: true,
                    color: 'var(--color6)',
                    offsetY: 1,
                    formatter: () => formatWeightValue(co2FootprintSumVO.perPerson.kg),
                  },
                  value: {
                    show: true,
                    fontFamily: 'Poppins',
                    color: 'var(--color6)',
                    offsetY: 10,
                    formatter: () => t('emiratesPersonal.pages.result.perPerson'),
                  },
                  total: {
                    show: true,
                    fontFamily: 'Poppins',
                    color: 'var(--color6)',
                    label: formatWeightValue(co2FootprintSumVO.perPerson.kg),
                    formatter: () => t('emiratesPersonal.pages.result.perPerson'),
                  },
                },
              },
            },
          },
        }}
        series={[
          co2FootprintsVOs.aroundCity.perPerson.formattedKg,
          co2FootprintsVOs.accomodation.perPerson.formattedKg,
          travelToMonacoCo2FootpintVO.perPerson.formattedKg,
        ]}
        type="donut"
      />
      <Text>{t('emiratesPersonal.pages.result.footprintDescription')}</Text>
      <FootprintLevel>{co2footPrintLevelNames[co2FootprintLevel]}</FootprintLevel>
      <ResultLine>
        <ResultTitle>
          <Mark $color="var(--chart-color2)" />
          {t('emiratesPersonal.pages.result.topContainer.marks.travelAround')}
        </ResultTitle>
        <ResultValue>{co2FootprintsVOs.aroundCity.perPerson.stringifiedValue}</ResultValue>
      </ResultLine>

      <ResultLine>
        <ResultTitle>
          <Mark $color="var(--chart-color3)" />
          {t('emiratesPersonal.pages.result.topContainer.marks.accommodation')}
        </ResultTitle>
        <ResultValue>{co2FootprintsVOs.accomodation.perPerson.stringifiedValue}</ResultValue>
      </ResultLine>

      <ResultLine>
        <ResultTitle>
          <Mark $color="var(--chart-color4)" />
          {t('emiratesPersonal.pages.result.topContainer.marks.travelTo', { fromAddress })}
        </ResultTitle>
        <ResultValue>{travelToMonacoCo2FootpintVO.perPerson.stringifiedValue}</ResultValue>
      </ResultLine>
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  padding: 28px 0 18px;
  border-radius: 20px;
  border: 2px solid var(--color8);
  margin: 0 auto;

  @media ${DEVICE.tablet} {
    min-width: 300px;
    max-width: 340px;
  }
  @media ${DEVICE.laptop} {
    margin: 0;
  }
`;

const Text = styled.div`
  margin-bottom: 16px;
  padding: 0 30px;
  ${TYPOGRAPHY.bodyRegular16}
  color: var(--color6);
  text-align: center;
`;

const FootprintLevel = styled.div`
  ${TYPOGRAPHY.titleSemibold32}
  color: var(--color6);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
`;

const StyledChart = styled(Chart)`
  position: relative;
  margin-bottom: 17px;
  .apexcharts-datalabel-label {
    ${TYPOGRAPHY.titleSemibold24}
    color: var(--color6);
  }

  .apexcharts-datalabel-value {
    ${TYPOGRAPHY.bodyMedium16}
    fill: var(--color6);
  }
`;
const ResultLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 0px 20px;
  &:last-child {
    margin-bottom: 0;
  }
  color: var(--color6);
`;

const Mark = styled.span<{ $color: string }>`
  width: 16px;
  height: 16px;
  border-radius: 6px;
  background-color: ${({ $color }) => $color};
  margin-right: 5px;
  flex-shrink: 0;
`;

const ResultTitle = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  color: var(--color6);

  ${TYPOGRAPHY.captionRegular12};
  color: var(--color6);
`;

const ResultValue = styled.div`
  ${TYPOGRAPHY.captionRegular12};
  white-space: nowrap;
  color: var(--color6);
`;
