import 'dayjs/locale/de';
import 'dayjs/locale/it';
import 'dayjs/locale/fr';
import dayjs from 'dayjs';
import { StorageService } from 'services';
import { Language } from 'common/types';

export class TimeUtil {
  static validate(param: string, format: string) {
    const currentDate = dayjs(param, format);
    const isValid = currentDate.format(format) === param;
    if (!isValid) {
      throw new Error(
        `Invalid date string ${param} for format ${format}. Date to compare: ${currentDate.toISOString()}`,
      );
    }
  }

  static now() {
    const locale = this.getCurrentLocale();
    return dayjs().locale(locale);
  }

  static getDiffOfSeconds(firstDate: Date | string, secondDate: Date | string) {
    return dayjs(secondDate).diff(firstDate, 'second');
  }

  static getCurrentLocale() {
    return StorageService.getItem('language') || Language.ENGLISH;
  }

  static getStartOFNowDay() {
    const locale = this.getCurrentLocale();
    return dayjs().locale(locale).startOf('date');
  }

  static parse(param: string, format: string) {
    this.validate(param, format);
    const locale = this.getCurrentLocale();
    return dayjs(param).locale(locale);
  }

  static getYear(date: string | Date) {
    return dayjs(date).year();
  }

  static getMonth(date: string | Date) {
    return dayjs(date).month();
  }

  static getDate(date: string | Date) {
    return dayjs(date).date();
  }

  static getDay(date: string | Date) {
    return dayjs(date).day();
  }

  static getFormatted(date: string | Date, format?: string) {
    const locale = this.getCurrentLocale();
    return dayjs(date).locale(locale).format(format);
  }
}
