import dayjs from 'dayjs';

export const calculateTripDaysCount = (...dates: [Date, Date]) => {
  const [startDate, endDate] = dates.map(dayjs);

  const startOfStartDate = startDate.startOf('d');
  const endOfEndDate = endDate.endOf('d');
  const tripDaysCount = endOfEndDate.add(1, 'd').diff(startOfStartDate, 'd');

  return tripDaysCount;
};
