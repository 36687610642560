import * as React from 'react';
import { SVGProps } from 'react';
const SvgCommittedDestinationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#committed-destionation-icon_svg__a)">
      <circle cx={20} cy={20} r={20} fill="#16B965" />
      <path
        d="m21.71 15.715-5.974-3.03-7.309 3.654v15.837l7.31-3.655 7.309 3.655 7.309-3.655V18.23"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m26.7 19.346-4.264-6.091a4.873 4.873 0 1 1 8.528 0l-4.264 6.09Z"
        fill="#16B965"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx={26.7} cy={10.908} r={2.029} stroke="#fff" />
      <path
        d="M13.273 24.92c1.627-1.513 1.627-3.966 0-5.48l-.737-.685-.736.685c-1.628 1.514-1.628 3.967 0 5.48l.736.685.737-.685ZM25.48 26.57c1.627-1.513 1.627-3.966 0-5.48l-.737-.685-.737.685c-1.627 1.514-1.627 3.967 0 5.48l.737.685.737-.685ZM19.627 24.166c1.895-1.762 1.895-4.62 0-6.382l-.857-.797-.858.797c-1.895 1.763-1.895 4.62 0 6.382l.858.797.857-.797Z"
        fill="#16B965"
        stroke="#fff"
        strokeLinejoin="round"
      />
      <path d="M12.536 27.303V21.69M24.743 28.953V23.34M18.77 26.94v-6.535" stroke="#fff" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="committed-destionation-icon_svg__a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCommittedDestinationIcon;
