import { WizardPageProps } from 'pages/MainPage/components/WizardForm/WizardForm';
import { ReactElement, ReactNode } from 'react';
import { Co2FootprintVO } from 'vo';

export type MapPoint = {
  lat: number;
  lng: number;
};

export enum StayingPlace {
  HOTEL_2_STARS = 'hotel-2-stars',
  HOTEL_3_STARS = 'hotel-3-stars',
  HOTEL_4_STARS = 'hotel-4-stars',
  HOTEL_5_STARS = 'hotel-5-stars',
  PRIVATE_ACCOMODATION = 'private-accomodation',
}

export enum StayingOvernight {
  YES = 'yes',
  NO = 'no',
}

export enum AirlineTicketClass {
  ECONOMY = 'economy',
  BUSINESS = 'business',
  FIRST_CLASS = 'first-class',
  PRIVATE_JET = 'private-jet',
}

export enum MainTransport {
  CAR = 'main-car',
  TRAIN = 'main-train',
  BUS = 'main-bus',
  CYCLE = 'main-cycle',
  WALK = 'main-walk',
  HELICOPTER = 'main-helicopter',
  AIRPLANE = 'main-airplane',
  SHIP = 'main-ship',
  ELECTRIC_CAR = 'main-electric-car',
}

export enum FromAirportTransport {
  BUS = 'from-airport-bus',
  TAXI = 'from-airport-taxi',
  TRAIN = 'from-airport-train',
  HELICOPTER = 'from-airport-helicopter',
}

export enum AroundCityTransport {
  WALK = 'around-city-walk',
  CYCLE = 'around-city-cycle',
  TAXI = 'around-city-taxi',
  BUS = 'around-city-bus',
  ELICTRIC_CAR_OR_TAXI = 'around-city-electric-car-or-taxi',
  PRIVATE_CAR = 'around-city-private-car',
}

export interface CalculatorFormValues {
  tripDuration?: [Date, Date];
  peopleCount?: number;
  fromLocation?: {
    mainAddress: string;
    fullAddress: string;
    mapPoint: MapPoint;
  };
  mainTransport?: MainTransport;
  fromAirportTransport?: FromAirportTransport;
  airlineTicketClass?: AirlineTicketClass;
  stayingOvernight?: StayingOvernight;
  stayingPlace?: StayingPlace;
  aroundCityTransports?: AroundCityTransport[];
}

export enum StepName {
  WELCOME = 'WELCOME',
  TRIP_DURATION = 'TRIP_DURATION',
  TRAVELERS = 'TRAVELERS',
  COUNTRY_AND_CITY = 'COUNTRY_AND_CITY',
  TRANSPORT = 'TRANSPORT',
  AIRLINE_CLASS_OF_SERVICE = 'AIRLINE_CLASS_OF_SERVICE',
  GETTING_INTO_CITY_FROM_AIRPORT = 'GETTING_INTO_CITY_FROM_AIRPORT',
  PLACE_TO_STAY = 'PLACE_TO_STAY',
  GETTING_AROUND_CITY = 'GETTING_AROUND_CITY',
}

export interface StepItemProps<T> {
  name: StepName;
  title: string;
  icon: ReactNode;
  page: ReactElement<WizardPageProps<T>>;
}

export enum LoadingStatus {
  IDLE = 'idle',
  PENDING = 'pending',
  FULFILLED = 'fulfilled',
  REJECTED = 'rejected',
}

export enum Language {
  ENGLISH = 'en',
  DEUTSCH = 'de',
  FRANÇAIS = 'fr',
  ITALIANO = 'it',
}

export enum Cookie {
  ACCEPT = 'monaco_travel_cookie_accept',
}

export type Co2FootprintsVOs = {
  fromTo: Co2FootprintVO;
  fromAirportToCity: Co2FootprintVO;
  accomodation: Co2FootprintVO;
  aroundCity: Co2FootprintVO;
};
