import React from 'react';

type Props = { title: string; value: string; color: string };

export const ListItem: React.FC<Props> = ({ title, value, color }) => {
  const colorDecimalNumber = parseInt(color.replace('#', '0x'), 16);
  return (
    <html>
      <head>
        <style>
          {`
            .list-item__root {
              
              font-style: normal;
              font-size: 20px;
              line-height: 30px;
              color: var(--color6);

              position: relative;
              display: block;
              padding-left: 24px;
              margin-bottom: 30px;
              list-style: none;
            }

            .item-${colorDecimalNumber}::before {
                content: '';
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: ${color};
                position: absolute;
                top: 30%;
                left: 0;
            }
          `}
        </style>
      </head>
      <li className={`list-item__root item-${colorDecimalNumber}`}>
        <span style={{ fontWeight: 500, marginRight: 'auto' }}>{title}</span>
        <span style={{ fontWeight: 400, float: 'right' }}>{value} CO2e</span>
      </li>
    </html>
  );
};
