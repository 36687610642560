import { CalculatorFormValues } from 'common/types';
import * as validators from 'validators';
import { FC, useState } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { containsOnlyNumbersChars } from 'utils';
import { WizardPageProps } from '../../WizardForm/WizardForm';
import { Input, RadioButton, StepDescription, StepTitle } from '@net0-calc/common/components/ui';

const fieldName = 'peopleCount';
const optionValues = [1, 2, 3, 4];

export const TravelersPage: FC<WizardPageProps<CalculatorFormValues>> = ({ form, values }) => {
  const { t } = useTranslation();
  const [isMoreSelected, setIsMoreSelected] = useState(
    Number(values?.peopleCount) > optionValues[optionValues.length - 1],
  );
  return (
    <div>
      <Root>
        <StepTitle>{t('emiratesPersonal.pages.travelers.title')}</StepTitle>
        <StepDescription
          dangerouslySetInnerHTML={{ __html: t('emiratesPersonal.pages.travelers.subTitle') }}></StepDescription>
        <RadioGroup>
          {optionValues.map(optionValue => {
            return (
              <Field
                key={optionValue}
                name={fieldName}
                type="radio"
                value={optionValue}
                validate={validators.isRequired}>
                {({ input }) => (
                  <RadioButton
                    id="one-people-option"
                    text={String(optionValue)}
                    {...input}
                    onChange={event => {
                      const { value } = event.currentTarget;
                      input.onChange(Number(value));
                    }}
                    onClick={() => setIsMoreSelected(false)}
                  />
                )}
              </Field>
            );
          })}
          <RadioButton
            text={t('emiratesPersonal.pages.travelers.form.field.peopleCount.radioButtonText')}
            checked={isMoreSelected}
            onChange={() => {
              setIsMoreSelected(true);
              form?.change(fieldName, undefined);
            }}
          />
        </RadioGroup>
        {isMoreSelected && (
          <Field type="text" name={fieldName} validate={validators.isRequired}>
            {field => {
              // TODO: make right
              const input = {
                ...field.input,
                onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                  if (typeof event === 'string') {
                    field.input.onChange(event as string);
                    return;
                  }

                  const targetValue = event.currentTarget.value;
                  if (targetValue.length === 0 || containsOnlyNumbersChars(targetValue)) {
                    field.input.onChange(Number(targetValue));
                  }
                },
              };
              return (
                <StyledInput
                  type="text"
                  label={t('emiratesPersonal.pages.travelers.form.field.peopleCount.label')}
                  {...field}
                  input={input}
                />
              );
            }}
          </Field>
        )}
      </Root>
    </div>
  );
};

const Root = styled.div`
  width: 100%;
  margin-bottom: 70px;
`;

const RadioGroup = styled.div`
  margin-bottom: 16px;
`;

const StyledInput = styled(Input)`
  width: 260px;
  white-space: nowrap;
  margin: 0;
`;
