import { SVGProps } from 'react';

const PngIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={25}
    height={24}
    viewBox="0 0 25 24"
    stroke="#B11425"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M20 3.75H5C4.58579 3.75 4.25 4.08579 4.25 4.5V19.5C4.25 19.9142 4.58579 20.25 5 20.25H20C20.4142 20.25 20.75 19.9142 20.75 19.5V4.5C20.75 4.08579 20.4142 3.75 20 3.75Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.75 14.9999L16.7844 11.0343C16.7146 10.9634 16.6314 10.9072 16.5397 10.8687C16.4479 10.8303 16.3495 10.8105 16.25 10.8105C16.1505 10.8105 16.0521 10.8303 15.9603 10.8687C15.8686 10.9072 15.7854 10.9634 15.7156 11.0343L11.5344 15.2155C11.4646 15.2864 11.3814 15.3427 11.2897 15.3811C11.1979 15.4195 11.0995 15.4393 11 15.4393C10.9005 15.4393 10.8021 15.4195 10.7103 15.3811C10.6186 15.3427 10.5354 15.2864 10.4656 15.2155L8.53437 13.2843C8.46459 13.2134 8.3814 13.1572 8.28966 13.1187C8.19792 13.0803 8.09946 13.0605 8 13.0605C7.90054 13.0605 7.80208 13.0803 7.71034 13.1187C7.6186 13.1572 7.53541 13.2134 7.46563 13.2843L4.25 16.4999"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9.875 9.75C10.4963 9.75 11 9.24632 11 8.625C11 8.00368 10.4963 7.5 9.875 7.5C9.25368 7.5 8.75 8.00368 8.75 8.625C8.75 9.24632 9.25368 9.75 9.875 9.75Z" />
  </svg>
);

export default PngIcon;
