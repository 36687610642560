import { CalculatorFormValues } from 'common/types';
import { useState } from 'react';

const initialFormValues: CalculatorFormValues = {
  tripDuration: undefined,
  peopleCount: 0,
  fromLocation: {
    mainAddress: '',
    fullAddress: '',
    mapPoint: {
      lat: 0,
      lng: 0,
    },
  },
};

export const useFormValues = () => {
  const [formValues, setFormValues] = useState<CalculatorFormValues>(initialFormValues);
  return { initialFormValues, formValues, setFormValues } as const;
};
