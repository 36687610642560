export const COLORS = {
  color1: '#1BAFAF' /* Primary Dark */,
  color2: '#31CACA' /* Primary Default */,
  color3: '#86DEDE' /* Primary Medium */,
  color4: '#C6F4F4' /* Primary Light */,
  color5: '#FFFFFF' /* White */,
  color6: '#202020' /*  Black */,
  color7: '#BCBBBB' /* Medium (Gray)*/,
  color8: '#F6F6F6' /* Extra Light ( Extra Light Gray) */,
  color9: '#797979' /* Dark (Dark Gray)*/,
  color10: '#E1E1E1' /* Light (Light Gray)*/,
  color11: '#1BAFAF' /* Green5*/,
  color12: '#FF4F61' /* Red */,
  color13: '#31CACA' /* Green */,
  color14: '#EAFAFA' /* Primary Extra light */,

  chartColor1: '#EF5E5E',
  chartColor2: '#FF974A',
  chartColor3: '#FFC542',
  chartColor4: '#00C2FF',
  chartColor5: '#0062FF',
};
