const kgToTon = (kg: number) => kg / 1000;
const kgToGram = (kg: number) => kg * 1000;

// VO is Value Object, read this article https://en.wikipedia.org/wiki/Value_object
class UnitVO {
  // value must be in kg
  constructor(readonly value: number) {}

  get gram() {
    return kgToGram(this.kg);
  }

  get kg() {
    return this.value;
  }

  get ton() {
    return kgToTon(this.value);
  }

  get formattedGram() {
    return Number(this.gram.toFixed(2));
  }

  get formattedKg() {
    return Number(this.kg.toFixed(2));
  }

  get formattedTon() {
    return Number(this.ton.toFixed(2));
  }

  get formattedValue() {
    const { kg } = this;
    if (kg >= 1000) {
      return this.formattedTon;
    }
    if (kg < 1) {
      return this.formattedGram;
    }
    return this.formattedKg;
  }

  get stringifiedGram() {
    return `${this.formattedGram} g`;
  }

  get stringifiedTon() {
    return `${this.formattedTon} t`;
  }

  get stringifiedKg() {
    return `${this.formattedKg} kg`;
  }

  get stringifiedValue() {
    const { kg } = this;
    if (kg >= 1000) {
      return this.stringifiedTon;
    }
    if (kg < 1) {
      return this.stringifiedGram;
    }
    return this.stringifiedKg;
  }

  get valueFillAnimationIncrementStepByKg() {
    const { kg } = this;

    return kg < 1000 ? 1 : kg < 10000 ? 10 : kg < 30000 ? 15 : kg < 100000 ? 100 : kg < 100000 ? 200 : 500;
  }
}

type Props = { perPerson: number; total: number };
export class Co2FootprintVO {
  static create(props: Props) {
    return new Co2FootprintVO(props);
  }

  static sum(elements: Array<Co2FootprintVO>) {
    const { perPerson, total } = elements.reduce(
      (acc, currentVO) => {
        const newPerPerson = acc.perPerson + currentVO.perPerson.kg;
        const newTotal = acc.total + currentVO.total.kg;
        return { perPerson: newPerPerson, total: newTotal };
      },
      { perPerson: 0, total: 0 },
    );

    return new Co2FootprintVO({ perPerson, total });
  }

  static avg(elements: Array<Co2FootprintVO>) {
    const elementsCount = elements.length;
    const sumVO = Co2FootprintVO.sum(elements);

    const totalAvgKg = sumVO.total.kg / elementsCount;
    const perPersonAvgKg = sumVO.perPerson.kg / elementsCount;

    const avgVO = Co2FootprintVO.create({ total: totalAvgKg, perPerson: perPersonAvgKg });
    return avgVO;
  }

  readonly total: UnitVO;
  readonly perPerson: UnitVO;

  constructor(props: Props) {
    this.total = new UnitVO(props.total);
    this.perPerson = new UnitVO(props.perPerson);
  }
}
