import {
  BusIcon,
  CalendarIcon,
  CarIcon,
  HomeIcon,
  MapIcon,
  PeopleIcon,
  PlaneIcon,
  StarIcon,
} from '@net0-calc/common/assets/svg';
import { CalculatorFormValues, MainTransport, StepItemProps, StepName } from 'common/types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DistanceVO } from 'vo';
import {
  ClassOfServicePage,
  CountryCityPage,
  GettingAroundPage,
  GettingIntoCityPage,
  PlaceToStayPage,
  TransportPage,
  TravelersPage,
  TripDurationPage,
  WelcomePage,
} from '../components/wizard-form-pages';

type Params = {
  distanceVO: DistanceVO;
  formValues: CalculatorFormValues;
};

export const useSteps = ({ formValues, distanceVO }: Params) => {
  const { t } = useTranslation();
  const [currentStepIndex, changeCurrentStepIndex] = useState(0);

  const stepsMap: Record<StepName, StepItemProps<CalculatorFormValues>> = {
    [StepName.WELCOME]: {
      name: StepName.WELCOME,
      title: '',
      icon: null,
      page: <WelcomePage />,
    },
    [StepName.TRIP_DURATION]: {
      name: StepName.TRIP_DURATION,
      title: t('emiratesPersonal.mainPage.steps.trip'),
      icon: <CalendarIcon />,
      page: <TripDurationPage />,
    },
    [StepName.TRAVELERS]: {
      name: StepName.TRAVELERS,
      title: t('emiratesPersonal.mainPage.steps.travelers'),
      icon: <PeopleIcon />,
      page: <TravelersPage />,
    },
    [StepName.COUNTRY_AND_CITY]: {
      name: StepName.COUNTRY_AND_CITY,
      title: t('emiratesPersonal.mainPage.steps.countryAndCity'),
      icon: <MapIcon />,
      page: <CountryCityPage />,
    },
    [StepName.TRANSPORT]: {
      name: StepName.TRANSPORT,
      title: t('emiratesPersonal.mainPage.steps.transport'),
      icon: <PlaneIcon />,
      page: <TransportPage distanceVO={distanceVO} />,
    },
    [StepName.AIRLINE_CLASS_OF_SERVICE]: {
      name: StepName.AIRLINE_CLASS_OF_SERVICE,
      title: t('emiratesPersonal.mainPage.steps.class'),
      icon: <StarIcon />,
      page: <ClassOfServicePage />,
    },
    [StepName.GETTING_INTO_CITY_FROM_AIRPORT]: {
      name: StepName.GETTING_INTO_CITY_FROM_AIRPORT,
      title: t('emiratesPersonal.mainPage.steps.gettingInto'),
      icon: <CarIcon />,
      page: <GettingIntoCityPage />,
    },
    [StepName.PLACE_TO_STAY]: {
      name: StepName.PLACE_TO_STAY,
      title: t('emiratesPersonal.mainPage.steps.place'),
      icon: <HomeIcon />,
      page: <PlaceToStayPage />,
    },
    [StepName.GETTING_AROUND_CITY]: {
      name: StepName.GETTING_AROUND_CITY,
      title: t('emiratesPersonal.mainPage.steps.gettingAround'),
      icon: <BusIcon />,
      page: <GettingAroundPage />,
    },
  };

  const isAirplaneTransport = formValues.mainTransport === MainTransport.AIRPLANE;
  const stepsNames = [
    StepName.WELCOME,
    StepName.TRIP_DURATION,
    StepName.TRAVELERS,
    StepName.COUNTRY_AND_CITY,
    StepName.TRANSPORT,
    isAirplaneTransport && StepName.AIRLINE_CLASS_OF_SERVICE,
    isAirplaneTransport && StepName.GETTING_INTO_CITY_FROM_AIRPORT,
    StepName.PLACE_TO_STAY,
    StepName.GETTING_AROUND_CITY,
  ].filter(Boolean) as StepName[];

  const steps = stepsNames.map(stepName => stepsMap[stepName]);

  const currentStep = steps[currentStepIndex];
  const currentStepName = currentStep.name;
  const isLastStep = currentStepIndex === steps.length - 1;

  return [steps, { changeCurrentStepIndex, currentStepIndex, currentStep, currentStepName, isLastStep }] as const;
};
