import { DEVICE, TYPOGRAPHY } from '@net0-calc/common/assets/styles';
import { ArrowIcon } from '@net0-calc/common/assets/svg';
import { DownloadMethodology } from '@net0-calc/common/components';
import { LOGO_MONACO } from 'common/constants/image-paths';
import { LanguageDropdown } from 'components';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

interface MainLayoutProps {
  children: ReactNode;
  onPrevStep?: () => void;
  isBackButtonVisible: boolean;
  isResultPage: boolean;
}

export const MainLayout: FC<MainLayoutProps> = ({ children, onPrevStep, isBackButtonVisible }) => {
  const { t } = useTranslation();

  return (
    <Root>
      <Header>
        <div className="container">
          <HeaderInner>
            <Link href={`https://net0.com/`} target="_blank" rel="noreferrer">
              <Logo src={LOGO_MONACO} alt="monaco logo" loading="lazy" />
            </Link>

            <div>
              {isBackButtonVisible && (
                <BackTextButton onClick={onPrevStep}>
                  <ArrowIcon />
                  <BackButtonText>{t('general.button.backButton')}</BackButtonText>
                </BackTextButton>
              )}
            </div>
            <RightBlock>
              <LanguageDropdown />
            </RightBlock>
          </HeaderInner>
        </div>
      </Header>
      <Main>
        <StyledContainer className="container">{children}</StyledContainer>
      </Main>
      {/* <CookieBanner /> */}
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const StyledContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Logo = styled.img`
  width: 258px;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.32);
  cursor: pointer;

  @media ${DEVICE.laptop} {
    transform: translate(-50%, -50%) scale(0.5);
  }
`;

const Link = styled.a`
  position: absolute;
  top: 50%;
  left: 50%;
  cursor: pointer;
`;

const Header = styled.header`
  position: sticky;
  top: 0px;
  left: 0px;
  z-index: 500;

  display: flex;
  align-items: center;

  width: 100%;
  min-height: 48px;
  padding: 0 20px;

  background-color: var(--color5);

  @media ${DEVICE.laptop} {
    min-height: 80px;
    padding: 0 80px;
    border-bottom: 2px solid var(--color8);
  }
`;

const HeaderInner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Main = styled.main`
  position: relative;
  width: 100%;
  padding: 20px 16px 50px;
  flex: 1;
  display: flex;
  flex-direction: column;
  @media ${DEVICE.laptop} {
    padding: 60px 80px 50px;
  }
`;

const BackTextButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;

  & svg {
    transform: rotate(90deg);
    margin-right: 8px;
    & path {
      stroke: var(--color2);
    }
  }
`;

const BackButtonText = styled.div`
  ${TYPOGRAPHY.bodyMedium20}
  color: var(--color2);
  display: none;

  @media ${DEVICE.laptop} {
    display: block;
  }
`;

const RightBlock = styled.div`
  display: flex;
`;

const StyledDownloadMethodology = styled(DownloadMethodology)`
  display: none;
  margin-right: 35px;

  @media ${DEVICE.laptop} {
    display: flex;
  }
`;
