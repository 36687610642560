import * as React from 'react';
import { SVGProps } from 'react';
const SvgSoftMobilityIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#soft-mobility-icon_svg__a)">
      <circle cx={20} cy={20} r={20} fill="#16B965" />
      <path
        d="M20.367 13.6c.848-3.576-1.405-6.95-5.033-7.537l-1.643-.265-.383 1.618c-.848 3.576 1.405 6.95 5.033 7.537l1.643.265.383-1.618Z"
        fill="#16B965"
        stroke="#fff"
        strokeLinejoin="round"
      />
      <path d="M20.458 17.713c-.712-3.885-1.696-5.677-4.172-8.397" stroke="#fff" strokeLinecap="round" />
      <path
        d="M13.1 16.567h1.7m1.7 0h-1.7m0 0 .956 1.7m4.144 7.366h-7.367l3.223-7.366m4.144 7.366-4.144-7.366m4.144 7.366 4.9-7.366h-9.044M21.6 16h2.267l3.966 9.633"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx={12.533} cy={25.633} r={4.533} stroke="#fff" />
      <circle cx={27.533} cy={25.633} r={4.533} stroke="#fff" />
    </g>
    <defs>
      <clipPath id="soft-mobility-icon_svg__a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSoftMobilityIcon;
