import { DEVICE } from '@net0-calc/common/assets/styles';
import { ClockIcon } from '@net0-calc/common/assets/svg';
import { StepDescription, StepTitle } from '@net0-calc/common/components/ui';
import { CalculatorFormValues } from 'common/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { WizardPageProps } from '../../WizardForm/WizardForm';

const WelcomePage: React.FC<WizardPageProps<CalculatorFormValues>> = () => {
  const { t } = useTranslation();

  return (
    <Root>
      <StepTitle dangerouslySetInnerHTML={{ __html: t('emiratesPersonal.pages.welcome.title') }} />
      <StepDescription dangerouslySetInnerHTML={{ __html: t('emiratesPersonal.pages.welcome.description') }} />
      <EstimationSection>
        <StyledClockIcon />
        <Text>{t('emiratesPersonal.pages.welcome.estimation')}</Text>
      </EstimationSection>
    </Root>
  );
};

// t('emiratesPersonal.pages.welcome.description')

export default WelcomePage;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 60px;

  @media ${DEVICE.laptop} {
    margin-bottom: 70px;
    height: auto;
    flex-grow: 1;
    justify-content: flex-start;
  }
`;

const EstimationSection = styled.div`
  display: flex;
  align-items: center;
`;

const StyledClockIcon = styled(ClockIcon)`
  margin-right: 10px;
  stroke: var(--color2);
`;

const Text = styled(StepDescription)`
  margin-bottom: 0px;
`;
