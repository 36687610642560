import { CalculatorFormValues, StayingOvernight, StayingPlace } from 'common/types';
import * as validators from 'validators';
import { ChipRadioButton } from 'components/ChipRadioButton';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { range } from 'utils';
import { FC } from 'react';
import { WizardPageProps } from '../../WizardForm/WizardForm';
import { DEVICE, TYPOGRAPHY } from '@net0-calc/common/assets/styles';
import { StarIcon } from '@net0-calc/common/assets/svg';
import { Hints, RadioButton, StepTitle } from '@net0-calc/common/components/ui';

const optionsTranslationPrefix = 'emiratesPersonal.pages.placeToStay.form.field.placeToStay';

export const PlaceToStayPage: FC<WizardPageProps<CalculatorFormValues>> = ({ values }) => {
  const { t } = useTranslation();

  const stayingPlaceOptions = [
    { stars: 2, title: t(`${optionsTranslationPrefix}.options.hotel`), value: StayingPlace.HOTEL_2_STARS },
    { stars: 3, title: t(`${optionsTranslationPrefix}.options.hotel`), value: StayingPlace.HOTEL_3_STARS },
    { stars: 4, title: t(`${optionsTranslationPrefix}.options.hotel`), value: StayingPlace.HOTEL_4_STARS },
    { stars: 5, title: t(`${optionsTranslationPrefix}.options.hotel`), value: StayingPlace.HOTEL_5_STARS },
    {
      stars: null,
      title: t(`${optionsTranslationPrefix}.options.accommodation`),
      value: StayingPlace.PRIVATE_ACCOMODATION,
    },
  ];

  const stayingOvernightOptions = [
    { title: t(`${optionsTranslationPrefix}.stayingOvernightOptions.no`), value: StayingOvernight.NO },
    { title: t(`${optionsTranslationPrefix}.stayingOvernightOptions.yes`), value: StayingOvernight.YES },
  ];

  return (
    <Root>
      <StepTitle>{t('emiratesPersonal.pages.placeToStay.title')}</StepTitle>
      <RadioGroup>
        {stayingOvernightOptions.map(({ value, title }) => {
          return (
            <Field key={value} name="stayingOvernight" type="radio" value={value} validate={validators.isRequired}>
              {({ input }) => <RadioButton id={`stayingOvernight-${value}`} text={String(title)} {...input} />}
            </Field>
          );
        })}
      </RadioGroup>
      {values?.stayingOvernight === StayingOvernight.YES && (
        <>
          <SubQuestionTitle>{t('emiratesPersonal.pages.placeToStay.subQuestionTitle')}</SubQuestionTitle>
          <SubQuestionSubTitle>{t('emiratesPersonal.pages.placeToStay.subQuestionSubtitle')}</SubQuestionSubTitle>
          <ChipsGroup>
            {stayingPlaceOptions.map(({ stars, value, title }) => (
              <Field key={value} name="stayingPlace" type="radio" value={value} validate={validators.isRequired}>
                {({ input }) => (
                  <ChipRadioButton id={`${value}-option`} {...input}>
                    {stars && (
                      <StarsWrap>
                        {range(stars).map(key => (
                          <StyledStarIcon key={key} />
                        ))}
                      </StarsWrap>
                    )}
                    {title}
                  </ChipRadioButton>
                )}
              </Field>
            ))}
          </ChipsGroup>
        </>
      )}
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
`;

const SubQuestionTitle = styled.div`
  ${TYPOGRAPHY.titleSemibold28}
  color: var(--color6);
  margin-bottom: 16px;

  @media ${DEVICE.laptop} {
    ${TYPOGRAPHY.titleSemibold36}
    margin-bottom: 20px;
  }
`;

const SubQuestionSubTitle = styled.div`
  ${TYPOGRAPHY.bodyRegular16}
  color: var(--color6);
  margin-bottom: 20px;

  @media ${DEVICE.laptop} {
    margin-bottom: 50px;
  }
`;

const RadioGroup = styled.div`
  margin-bottom: 50px;
`;

const ChipsGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
  column-gap: 20px;
  row-gap: 16px;
  flex-direction: column;
  align-items: flex-start;
  @media ${DEVICE.laptop} {
    flex-direction: row;
    row-gap: 20px;
  }
`;

const StarsWrap = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-right: 10px;
`;

const StyledStarIcon = styled(StarIcon)`
  &:not(&:last-child) {
    margin-right: 8px;
  }
`;

const StyledHints = styled(Hints)`
  margin-bottom: 20px;

  @media ${DEVICE.laptop} {
    margin-bottom: 70px;
  }
`;
