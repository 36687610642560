import styled from 'styled-components/macro';
import { CalculatorFormValues } from 'common/types';
import { FC } from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import { WizardPageProps } from '../../WizardForm/WizardForm';
import { SearchAddressInput } from 'components';
import { SearchAddressInputValue } from 'components/SearchAddressInput';
import { useTranslation } from 'react-i18next';
import { DEVICE } from '@net0-calc/common/assets/styles';
import { StepDescription, StepTitle } from '@net0-calc/common/components/ui';

export const CountryCityPage: FC<WizardPageProps<CalculatorFormValues>> = () => {
  const { t } = useTranslation();

  const validate = (value: SearchAddressInputValue) => {
    if (!value.fullAddress || !value.mainAddress)
      return t('emiratesPersonal.pages.countryCity.form.errorMessages.noAddress');
    return undefined;
  };

  return (
    <Root>
      <StepTitle>{t('emiratesPersonal.pages.countryCity.title')}</StepTitle>
      <StepDescription>{t('emiratesPersonal.pages.countryCity.subtitle')}</StepDescription>
      <Field<SearchAddressInputValue> name="fromLocation" validate={validate}>
        {(field: FieldRenderProps<SearchAddressInputValue>) => (
          <SearchAddressInput
            label={t('emiratesPersonal.pages.countryCity.form.field.fromLocation.label')}
            {...field}
          />
        )}
      </Field>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  margin-bottom: 24px;
  @media ${DEVICE.laptop} {
    margin-bottom: 70px;
  }
`;
