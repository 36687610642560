// VO is Value Object, read this article https://en.wikipedia.org/wiki/Value_object
type Unit = 'km';

export class DistanceVO {
  static create(value: number, unit: Unit = 'km') {
    return new DistanceVO(value, unit);
  }

  // value must be in km
  constructor(private readonly value: number, private readonly unit: Unit) {}

  get km() {
    return this.value;
  }
}
