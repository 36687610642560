import { AroundCityTransport, CalculatorFormValues } from 'common/types';
import * as validators from 'validators';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { WizardPageProps } from '../../WizardForm/WizardForm';
import { FC } from 'react';
import {
  LargeBusIcon,
  LargeCarIcon,
  LargeCycleIcon,
  LargeElectroCar,
  LargeTaxiIcon,
  LargeWalkIcon,
} from '@net0-calc/common/assets/svg';
import { DEVICE } from '@net0-calc/common/assets/styles';
import { CardRadioButton, Hints, StepDescription, StepTitle } from '@net0-calc/common/components/ui';

const optionsTranslationPrefix = 'general.options.transport';

type GettingAroundPageProps = WizardPageProps<CalculatorFormValues>;

const { BUS, CYCLE, ELICTRIC_CAR_OR_TAXI, PRIVATE_CAR, TAXI, WALK } = AroundCityTransport;

export const GettingAroundPage: FC<GettingAroundPageProps> = () => {
  const { t } = useTranslation();

  const options = [
    {
      value: WALK,
      title: t(`${optionsTranslationPrefix}.walk`),
      icon: <LargeWalkIcon />,
    },
    { value: CYCLE, title: t(`${optionsTranslationPrefix}.cycle`), icon: <LargeCycleIcon /> },
    { value: TAXI, title: t(`${optionsTranslationPrefix}.taxi`), icon: <LargeTaxiIcon /> },
    { value: BUS, title: t(`${optionsTranslationPrefix}.bus`), icon: <LargeBusIcon /> },
    {
      value: PRIVATE_CAR,
      title: t(`${optionsTranslationPrefix}.privateCar`),
      icon: <LargeCarIcon />,
    },
    {
      value: ELICTRIC_CAR_OR_TAXI,
      title: t(`${optionsTranslationPrefix}.electricCarOrTaxi`),
      icon: <LargeElectroCar />,
    },
  ];

  return (
    <Root>
      <StepTitle>{t('emiratesPersonal.pages.gettingAround.title')}</StepTitle>
      <StepDescription>{t('emiratesPersonal.pages.gettingAround.subTitle')}</StepDescription>
      <CardsGroup>
        {options.map(({ value, title, icon }) => (
          <Field
            key={value}
            name="aroundCityTransports"
            value={value}
            type="checkbox"
            validate={validators.isCheckboxRequired}>
            {({ input }) => <CardRadioButton id={`${value}-option`} icon={icon} title={title} {...input} />}
          </Field>
        ))}
      </CardsGroup>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
`;

const CardsGroup = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(2, 165px);
  grid-gap: 13px;
  margin-bottom: 16px;

  @media ${DEVICE.tablet} {
    justify-content: flex-start;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, 195px);
    margin-bottom: 20px;
  }
`;

const StyledHints = styled(Hints)`
  margin-bottom: 60px;

  @media ${DEVICE.tablet} {
    margin-bottom: 70px;
  }
`;
