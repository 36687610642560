import * as React from 'react';
import { SVGProps } from 'react';

const SvgLargeTrainIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={61} height={60} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M42.75 55h-24.5c-4.25 0-7.75-3.475-7.75-7.75v-34.5C10.5 8.5 13.975 5 18.25 5h24.5C47 5 50.5 8.475 50.5 12.75v34.5c0 4.275-3.475 7.75-7.75 7.75Z"
      stroke="#B11425"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46.75 32.5h-32.5a3.745 3.745 0 0 1-3.75-3.75v-5A3.745 3.745 0 0 1 14.25 20h32.5a3.745 3.745 0 0 1 3.75 3.75v5a3.745 3.745 0 0 1-3.75 3.75Z"
      stroke="#B11425"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.736 44.25h.023M39.236 44.25h.023"
      stroke="#B11425"
      strokeWidth={5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M24.25 12.5h12.5" stroke="#B11425" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default SvgLargeTrainIcon;
