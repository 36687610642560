import calculateAccomodation from './calculate-accomodation';
import calculateMovement from './calculate-movement';
import calculateAroundCityMovement from './calculate-around-city-movement';
import calculateIntoCityFromAirportMovement from './calculate-into-city-from-airport-movement';

const co2FootprintCalculator = {
  calculateMovement,
  calculateAccomodation,
  calculateAroundCityMovement,
  calculateIntoCityFromAirportMovement,
};

export { co2FootprintCalculator };
