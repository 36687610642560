import { DEVICE } from '@net0-calc/common/assets/styles';
import { Hints, StepDescription, StepTitle } from '@net0-calc/common/components/ui';
import { AirlineTicketClass } from 'common/types';
import { ChipRadioButton } from 'components/ChipRadioButton';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import * as validators from 'validators';

const optionsTranslationPrefix = 'emiratesPersonal.pages.classOfService.form.field.airlineTicketClass.options';

export const ClassOfServicePage = () => {
  const { t } = useTranslation();

  const options = [
    {
      title: t(`${optionsTranslationPrefix}.economy`),
      value: AirlineTicketClass.ECONOMY,
    },
    { title: t(`${optionsTranslationPrefix}.business`), value: AirlineTicketClass.BUSINESS },
    { title: t(`${optionsTranslationPrefix}.firstClass`), value: AirlineTicketClass.FIRST_CLASS },
    { title: t(`${optionsTranslationPrefix}.privateJet`), value: AirlineTicketClass.PRIVATE_JET },
  ];

  return (
    <Root>
      <StepTitle>{t('emiratesPersonal.pages.classOfService.title')}</StepTitle>
      <StepDescription>{t('emiratesPersonal.pages.classOfService.subtitle')}</StepDescription>
      <ChipsGroup>
        {options.map(({ title, value }) => {
          return (
            <Field key={value} name="airlineTicketClass" type="radio" value={value} validate={validators.isRequired}>
              {({ input }) => (
                <ChipRadioButton id={`${value}-option`} {...input}>
                  {title}
                </ChipRadioButton>
              )}
            </Field>
          );
        })}
      </ChipsGroup>
      <StyledHints hints={[t('emiratesPersonal.pages.classOfService.hint')]} />
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
`;

const ChipsGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  row-gap: 16px;
  column-gap: 20px;
  @media ${DEVICE.laptop} {
    margin-bottom: 50px;
  }
`;

const StyledHints = styled(Hints)`
  margin-bottom: 61px;

  @media ${DEVICE.laptop} {
    margin-bottom: 70px;
  }
`;
