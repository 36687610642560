import { FC, useRef } from 'react';
import styled from 'styled-components/macro';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ChartDonutSection, TotalFootprintValue } from './components';
import { Co2FootprintVO } from 'vo';
import { useTranslation } from 'react-i18next';
import { CalculatorFormValues } from 'common/types';
import { ReductionOpportunities } from '../ReductionOpportunities';
import { DEVICE, TYPOGRAPHY } from '@net0-calc/common/assets/styles';

gsap.registerPlugin(ScrollTrigger);

interface TopContainerProps {
  co2FootprintSumVO: Co2FootprintVO;
  co2FootprintsVOs: {
    fromTo: Co2FootprintVO;
    fromAirportToCity: Co2FootprintVO;
    accomodation: Co2FootprintVO;
    aroundCity: Co2FootprintVO;
  };
  formValues: CalculatorFormValues;
}

export const TopContainer: FC<TopContainerProps> = ({ co2FootprintSumVO, co2FootprintsVOs, formValues }) => {
  const { t } = useTranslation();

  const containerRef = useRef<HTMLDivElement>(null);

  const travelToMonacoCo2FootpintVO = Co2FootprintVO.sum([co2FootprintsVOs.fromTo, co2FootprintsVOs.fromAirportToCity]);
  const title = t(`tepaTravel.pages.result.topContainer.title`);
  const fromAddress = formValues.fromLocation?.mainAddress || 'Karaganda';

  return (
    <Root ref={containerRef}>
      <SectionLeft>
        <Title>
          <span dangerouslySetInnerHTML={{ __html: title }}></span>
          <TotalFootprintValue value={co2FootprintSumVO.total.kg} />
        </Title>

        <MobileChartDonutSection {...{ fromAddress, co2FootprintSumVO, co2FootprintsVOs }} />
        <Subtitle>{t('emiratesPersonal.pages.result.topContainer.subTitle')}</Subtitle>
        <ResultLineWrapper>
          <ResultLine>
            <ResultTitle>
              <Mark $color="var(--chart-color2)" />
              {t('emiratesPersonal.pages.result.topContainer.marks.travelAround')}
            </ResultTitle>
            <ResultValue>{co2FootprintsVOs.aroundCity.total.stringifiedValue}</ResultValue>
          </ResultLine>

          <ResultLine>
            <ResultTitle>
              <Mark $color="var(--chart-color3)" />
              {t('emiratesPersonal.pages.result.topContainer.marks.accommodation')}
            </ResultTitle>
            <ResultValue>{co2FootprintsVOs.accomodation.total.stringifiedValue}</ResultValue>
          </ResultLine>

          <ResultLine>
            <ResultTitle>
              <Mark $color="var(--chart-color4)" />
              {t('emiratesPersonal.pages.result.topContainer.marks.travelTo', { fromAddress })}
            </ResultTitle>
            <ResultValue>{travelToMonacoCo2FootpintVO.total.stringifiedValue}</ResultValue>
          </ResultLine>
        </ResultLineWrapper>
        <ReductionOpportunities formValues={formValues} />
      </SectionLeft>
      <DesktopChartDonutSection {...{ fromAddress, co2FootprintSumVO, co2FootprintsVOs }} />
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 40px;
  order: 1;

  @media ${DEVICE.tablet} {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 60px;
  }
`;

const SectionLeft = styled.div`
  max-width: 850px;
  width: 100%;
`;

const Title = styled.h1`
  ${TYPOGRAPHY.titleSemibold28}

  color: var(--color6);
  margin-bottom: 20px;

  @media ${DEVICE.laptop} {
    ${TYPOGRAPHY.titleSemibold44}
    margin-bottom: 44px;
  }
`;

const Subtitle = styled.h6`
  ${TYPOGRAPHY.bodyMedium20}
  color: var(--color6);
  margin-bottom: 20px;

  @media ${DEVICE.laptop} {
    ${TYPOGRAPHY.titleSemibold32}
    margin-bottom: 32px;
  }
`;
const ResultLineWrapper = styled.div`
  margin-bottom: 40px;
  @media ${DEVICE.laptop} {
    margin-bottom: 60px;
  }
`;
const ResultLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: 24px;
  }
  @media ${DEVICE.laptop} {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
`;

const Mark = styled.span<{ $color: string }>`
  width: 24px;
  height: 24px;
  border-radius: 10px;
  background-color: ${({ $color }) => $color};
  margin-right: 12px;
  flex-shrink: 0;
`;

const ResultTitle = styled.div`
  ${TYPOGRAPHY.bodyRegular16}
  color: var(--color6);
  display: flex;
  align-items: center;

  @media ${DEVICE.laptop} {
    $${TYPOGRAPHY.boydRegular20}
  }
`;

const ResultValue = styled.div`
  ${TYPOGRAPHY.bodyRegular16}
  color: var(--color6);
  white-space: nowrap;
  @media ${DEVICE.laptop} {
    $${TYPOGRAPHY.boydRegular20}
  }
`;

const MobileChartDonutSection = styled(ChartDonutSection)`
  display: block;
  margin-bottom: 24px;

  @media ${DEVICE.tablet} {
    display: none;
  }
`;

const DesktopChartDonutSection = styled(ChartDonutSection)`
  display: none;

  @media ${DEVICE.tablet} {
    display: block;
  }
`;
