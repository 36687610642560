import * as React from 'react';
import { SVGProps } from 'react';

const SvgLargePlaneIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={61} height={60} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m23.075 54.225 5.825-4.9c.875-.75 2.325-.75 3.2 0l5.825 4.9c1.35.675 3 0 3.5-1.45l1.1-3.325c.275-.8 0-1.975-.6-2.575l-5.675-5.7c-.425-.4-.75-1.2-.75-1.775v-7.125c0-1.05.775-1.55 1.75-1.15l12.275 5.3c1.925.825 3.5-.2 3.5-2.3V30.9c0-1.675-1.25-3.6-2.8-4.25L36.25 20.625c-.4-.175-.75-.7-.75-1.15v-7.5c0-2.35-1.725-5.125-3.825-6.2a2.648 2.648 0 0 0-2.375 0C27.2 6.85 25.475 9.65 25.475 12v7.5c0 .45-.35.975-.75 1.15l-13.95 6.025c-1.55.625-2.8 2.55-2.8 4.225v3.225c0 2.1 1.575 3.125 3.5 2.3l12.275-5.3c.95-.425 1.75.1 1.75 1.15V39.4c0 .575-.325 1.375-.725 1.775l-5.675 5.7c-.6.6-.875 1.75-.6 2.575l1.1 3.325c.45 1.45 2.1 2.15 3.475 1.45Z"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgLargePlaneIcon;
