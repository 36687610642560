import { FC, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { api } from 'api';
import { toast } from 'services';
import { useTranslation } from 'react-i18next';
import { ReceiveResultForm } from '@net0-calc/common/components';
import { ReceiveResultByMailFormValues } from '@net0-calc/common/types';

gsap.registerPlugin(ScrollTrigger);

interface ReceiveResultFormProps {
  answerId: string | null;
}

export const ReceiveResult: FC<ReceiveResultFormProps> = ({ answerId }) => {
  const { t, i18n } = useTranslation();
  const [isSubmitting, setIsSubmiting] = useState(false);

  const handleResultSubmit = (values: ReceiveResultByMailFormValues) => {
    if (answerId) {
      setIsSubmiting(true);
      api
        .sendEmail({ answerId, email: values.email, localization: i18n.resolvedLanguage })
        .then(() => toast.success(t('common.receiveResults.toastMessages.emailSent')))
        .catch(() => toast.error(t('common.receiveResults.toastMessages.tryAgain')))
        .finally(() => {
          setIsSubmiting(false);
        });
    }
  };

  return <ReceiveResultForm isSubmitting={isSubmitting} onSubmit={handleResultSubmit} />;
};
