import { useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components/macro';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useTranslation } from 'react-i18next';
import {
  AirlineTicketClass,
  AroundCityTransport,
  CalculatorFormValues,
  FromAirportTransport,
  MainTransport,
  StayingPlace,
} from 'common/types';
import { MONACO_MAP_POINT } from 'common/constants/map-point';
import * as utils from 'utils';
import { DEVICE, TYPOGRAPHY } from '@net0-calc/common/assets/styles';
import { BadIcon } from '@net0-calc/common/assets/svg';

gsap.registerPlugin(ScrollTrigger);

const translationPrefix = 'emiratesPersonal.pages.result.factors';

type Props = {
  formValues: CalculatorFormValues;
};

export const Factors = ({ formValues }: Props) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const blocksAnimationTimeLine = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        scrub: 1,
        start: 'top 600px',
        end: 'bottom bottom',
      },
    });
    blocksAnimationTimeLine
      .from(containerRef.current, {
        opacity: 0,
      })
      .to(containerRef.current, {
        opacity: 1,
        duration: 1,
      });
  }, []);

  /**
   * TODO: think about, should this logic be moved to the FormValuesChecker class
   * const formValuesChecker = new FormValuesChecker(formValues);
   * formValuesChecker.mainTransport.isWalk();
   * formValuesChecker.stayingPlace.isPrivateAccomodation();
   * formValuesChecker.stayingPlace.isPrivateAccomodation();
   * formValuesChecker.fromLocation.distance.more(300, 'km');
   */
  const isMainTransportAirplane = formValues.mainTransport === MainTransport.AIRPLANE;
  const isMainTransportWalk = formValues.mainTransport === MainTransport.WALK;
  const isMainTransportCycle = formValues.mainTransport === MainTransport.CYCLE;
  const isArilineTicketClassEconomy = formValues.airlineTicketClass === AirlineTicketClass.ECONOMY;
  const isFromAirportTransportTrain = formValues.fromAirportTransport === FromAirportTransport.TRAIN;
  const isStayingPlacePrivateAccomodation = formValues.stayingPlace === StayingPlace.PRIVATE_ACCOMODATION;

  const aroundCityTransports = formValues.aroundCityTransports || [];
  const isAroundCityTransportOnlyWalkOrCycle = utils.hasOnly(aroundCityTransports, [
    AroundCityTransport.WALK,
    AroundCityTransport.CYCLE,
  ]);
  const isAroundCityTransportHasPrivateCar = aroundCityTransports.includes(AroundCityTransport.PRIVATE_CAR);
  const isAroundCityTransportHasTaxi = aroundCityTransports.includes(AroundCityTransport.TAXI);
  const isAroundCityTransportHasBus = aroundCityTransports.includes(AroundCityTransport.BUS);
  const isAroundCityTransportHasElectricCarOrTaxi = aroundCityTransports.includes(
    AroundCityTransport.ELICTRIC_CAR_OR_TAXI,
  );

  const distacneToMonakoKm = formValues.fromLocation
    ? utils.calculateKmDistanceBetweenMapPoints(formValues.fromLocation?.mapPoint, MONACO_MAP_POINT)
    : 0;

  const renderItem = (condition: boolean, params: { title: string }) => {
    const { title } = params;
    return (
      condition && (
        <StepTitleWrap key={title}>
          <BadIcon stroke="var(--color2)" />
          <p>{title}</p>
        </StepTitleWrap>
      )
    );
  };

  const peopleCount = formValues.peopleCount || 0;
  const factorsList = [
    renderItem(isStayingPlacePrivateAccomodation, {
      title: t(`${translationPrefix}.stayInPrivateAccomodation`),
    }),
    renderItem((peopleCount || 0) > 1, {
      title: t(`${translationPrefix}.shareTransportWithAGroupOfPeople`, {
        peopleCount: (peopleCount || 0) - 1,
      }),
    }),
    renderItem(isMainTransportAirplane && isFromAirportTransportTrain, {
      title: t(`${translationPrefix}.takeTrainFromAirport`),
    }),
    renderItem(isAroundCityTransportOnlyWalkOrCycle, {
      title: t(`${translationPrefix}.walkOrCycleInTheCity`),
    }),
    renderItem(
      !isAroundCityTransportHasPrivateCar &&
        (isAroundCityTransportHasTaxi || isAroundCityTransportHasBus || isAroundCityTransportHasElectricCarOrTaxi),
      {
        title: t(`${translationPrefix}.notBringOwnCarIntoCity`),
      },
    ),
    renderItem(isMainTransportAirplane && isArilineTicketClassEconomy, {
      title: t(`${translationPrefix}.flyingEconomyClass`),
    }),
    renderItem(distacneToMonakoKm <= 300 && (isMainTransportWalk || isMainTransportCycle), {
      title: t(`${translationPrefix}.gettingToMonacoByWalkingOrCycling`),
    }),
  ].filter(Boolean);

  if (factorsList.length === 0) {
    return null;
  }

  return (
    <Root ref={containerRef}>
      <Title>
        <EmojiContainer>🌳</EmojiContainer> {t(`${translationPrefix}.title`)}
      </Title>
      {factorsList}
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  max-width: 845px;
  margin-bottom: 40px;
  order: 2;
  @media ${DEVICE.laptop} {
    margin-bottom: 60px;
  }
`;

const Title = styled.div`
  ${TYPOGRAPHY.bodyMedium20}
  color: var(--color6);
  margin-bottom: 20px;
  display: flex;

  @media ${DEVICE.laptop} {
    ${TYPOGRAPHY.titleSemibold32}
    margin-bottom: 32px;
  }
`;

const StepTitleWrap = styled.div`
  $${TYPOGRAPHY.boydRegular20}
  color: var(--color6);

  display: flex;
  align-items: flex-start;
  & svg {
    overflow: visible;
    margin-right: 12px;
    @media ${DEVICE.mobileL} {
      width: 32px;
    }
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }
  @media ${DEVICE.laptop} {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
`;

const emojiPulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
`;

const EmojiContainer = styled.div`
  cursor: pointer;
  margin-right: 8px;
  &:hover {
    animation: ${emojiPulseAnimation} 0.2s ease-in normal;
  }
`;
