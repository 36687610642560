import { AroundCityTransport } from 'common/types';
import { Co2FootprintVO } from 'vo';

// TODO: think about move this logic to calculate-movement.ts

const AVERAGE_COVERED_DISTANCE_KM_PER_DAY = 5;

const getCo2ePerKmByTransport = (transport: AroundCityTransport) => {
  /**
   * taken from DEFRA Dataset "Business travel- land" tab
   * https://docs.google.com/spreadsheets/d/1_1fVicqbKRj3VOewv0SA9QyLj3DI5HFL/edit#gid=1284393118
   */
  const mapping = {
    [AroundCityTransport.WALK]: 0,
    [AroundCityTransport.CYCLE]: 0,
    [AroundCityTransport.TAXI]: 0.14549, // Regular taxi passenger.km
    [AroundCityTransport.BUS]: 0.10312, // Average local bus
    [AroundCityTransport.PRIVATE_CAR]: 0.16637, // Medium car
    [AroundCityTransport.ELICTRIC_CAR_OR_TAXI]: 0.28991, // Electro car
  };
  return mapping[transport];
};

type Params = {
  transport: AroundCityTransport;
  peopleCount: number;
  tripDaysCount: number;
};

export default (params: Params) => {
  const { transport, tripDaysCount, peopleCount } = params;

  const isZeroCo2FootprintTransport = [AroundCityTransport.WALK, AroundCityTransport.CYCLE].includes(transport);
  if (isZeroCo2FootprintTransport) {
    return Co2FootprintVO.create({ perPerson: 0, total: 0 });
  }

  const perKm = getCo2ePerKmByTransport(transport);
  const perPerson = perKm * AVERAGE_COVERED_DISTANCE_KM_PER_DAY * tripDaysCount;
  const total = perPerson * peopleCount;

  return Co2FootprintVO.create({ perPerson, total });
};
