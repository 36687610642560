import styled, { keyframes, css } from 'styled-components/macro';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DEVICE, TYPOGRAPHY } from '@net0-calc/common/assets/styles';
import { CommittedDestinationIcon, GreenEarth, RightArrowIcon, SoftMobilityIcon } from '@net0-calc/common/assets/svg';

gsap.registerPlugin(ScrollTrigger);

const translationPrefix = 'emiratesPersonal.pages.result.tipsToReduce';

export const TipsToReduce = () => {
  const { t, i18n } = useTranslation();
  const resolvedLanguage = i18n.resolvedLanguage || 'en';
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const blocksAnimationTimeLine = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        scrub: 1,
        start: 'top 600px',
        end: 'bottom bottom',
      },
    });
    blocksAnimationTimeLine
      .from(containerRef.current, {
        opacity: 0,
      })
      .to(containerRef.current, {
        opacity: 1,
        duration: 1,
      });
  }, []);

  return (
    <Root ref={containerRef}>
      <InfoWrapper>
        <Title>
          <EmojiContainer>💡</EmojiContainer> {t(translationPrefix + '.title')}
        </Title>
        <div>
          <Paragraph>{t(translationPrefix + '.description.paragraph1')}</Paragraph>
          <Paragraph>{t(translationPrefix + '.description.paragraph3')}</Paragraph>
          <Paragraph>{t(translationPrefix + '.description.paragraph5')}</Paragraph>
        </div>
      </InfoWrapper>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  margin-bottom: 40px;
  order: 3;
  @media ${DEVICE.laptop} {
    margin-bottom: 60px;
  }
`;

const InfoWrapper = styled.div`
  max-width: 845px;
`;

const Title = styled.div`
  ${TYPOGRAPHY.bodyMedium20}
  color: var(--color6);
  margin-bottom: 16px;
  display: flex;
  @media ${DEVICE.laptop} {
    ${TYPOGRAPHY.titleSemibold32}
    margin-bottom: 32px;
  }
`;

const Paragraph = styled.div`
  $${TYPOGRAPHY.boydRegular20}
  color: var(--color6);
  width: 100%;
  margin-bottom: 32px;
  &:last-of-type {
    margin-bottom: 24px;
  }
`;

const ParagraphLink = styled.a`
  text-decoration: none;
  color: var(--color2);
`;

const LinksWrapper = styled.div`
  max-width: 1117px;
  display: flex;
  flex-direction: column;

  @media ${DEVICE.laptop} {
    flex-direction: row;
  }
`;

const Link = styled.a`
  ${TYPOGRAPHY.bodyMedium16}
  max-width: 400px;
  padding: 12px;
  display: flex;
  align-items: center;
  border-radius: 15px;
  border: 2px solid var(--color14);
  color: var(--color2);
  text-decoration: none;
  transition: 0.3s;

  &:not(:last-child) {
    margin: 0 0 12px;
  }

  @media ${DEVICE.laptop} {
    ${TYPOGRAPHY.bodyMedium20}

    width: auto;
    padding: 20px;

    &:not(:last-child) {
      margin: 0 20px 0 0;
    }
  }

  &:hover {
    border: 2px solid var(--color2);
  }
`;

const LinkText = styled.p`
  flex: 1;
`;

const iconsCommonCSS = css`
  margin-right: 16px;
`;

const StyledCommittedDestinationIcon = styled(CommittedDestinationIcon)`
  ${iconsCommonCSS}
`;

const StyledSoftMobilityIcon = styled(SoftMobilityIcon)`
  ${iconsCommonCSS}
`;
const StyledGreenEarth = styled(GreenEarth)`
  ${iconsCommonCSS}
`;

const StyledRightArrowIcon = styled(RightArrowIcon)`
  margin-left: 16px;
  stroke: var(--color3);
`;

const emojiPulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
`;

const EmojiContainer = styled.div`
  cursor: pointer;
  margin-right: 8px;
  &:hover {
    animation: ${emojiPulseAnimation} 0.2s ease-in normal;
  }
`;
