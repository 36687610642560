import { Language } from '@net0-calc/common/types';
import { CalculatorFormValues } from 'common/types';
import { config } from 'config';
import { HttpClient } from 'services';

type CO2FootprintAnswer = {
  totalKg: number;
  perPersonKg: number;
};

export type Answer = {
  id: string;
  answers: {
    formValues: CalculatorFormValues;
    co2Footprint: {
      fromTo: CO2FootprintAnswer;
      fromAirportToCity: CO2FootprintAnswer;
      accomodation: CO2FootprintAnswer;
      aroundCity: CO2FootprintAnswer;
      sum: CO2FootprintAnswer;
    };
  };
};

export const api = {
  sendAnswers({ answers }: { answers: any }) {
    const url = ['calculators', config.API_CALCULATOR_ID, 'answers'].join('/');
    return HttpClient.post(url, { answers });
  },
  sendEmail({ answerId, email, localization }: { answerId: string; email: string; localization?: string }) {
    const url = ['answers', answerId, 'send-email'].join('/');
    return HttpClient.get(url, {
      params: {
        email,
      },
      headers: {
        ['x-client-language']: localization || Language.ENGLISH,
      },
    });
  },
  getAnswerById(answerId: string) {
    const url = ['answer', answerId].join('/');
    return HttpClient.get<Answer>(url);
  },
  getResultImage(answerId: string, html: string) {
    const url = ['answer', answerId, 'generate-html-to-png'].join('/');
    return HttpClient.post<string>(url, { html });
  },
};
