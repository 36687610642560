import { Field, FieldRenderProps } from 'react-final-form';
import styled from 'styled-components/macro';
import * as validators from 'validators';
import { WizardPageProps } from '../../WizardForm/WizardForm';
import { CalculatorFormValues } from 'common/types';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DoubleCalendar, StepTitle, StepDescription } from '@net0-calc/common/components/ui';
import { DEVICE } from '@net0-calc/common/assets/styles';
import { useAppSelector, selectors } from 'store';

export const TripDurationPage: FC<WizardPageProps<CalculatorFormValues>> = () => {
  const locale = useAppSelector(selectors.dictionary.selectLanguage);
  const { t } = useTranslation();

  return (
    <Root>
      <StepTitle>{t('emiratesPersonal.pages.tripDuration.title')}</StepTitle>
      <StepDescription>{t('emiratesPersonal.pages.tripDuration.subtitle')}</StepDescription>
      <FormWrap>
        <Field name="tripDuration" validate={validators.isRequired}>
          {({ input }: FieldRenderProps<any>) => (
            <DoubleCalendar
              locale={locale}
              firstLabel={t('emiratesPersonal.pages.tripDuration.form.field.tripDuration.firstLabel')}
              secondLabel={t('emiratesPersonal.pages.tripDuration.form.field.tripDuration.secondLabel')}
              {...input}
              defaultValue={input.value}
            />
          )}
        </Field>
      </FormWrap>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  margin-bottom: 24px;

  @media ${DEVICE.laptop} {
    margin-bottom: 70px;
  }
`;

const FormWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;
